import React from "react";
import { useRoutes } from "react-router-dom";
import routes from "./routes";

const Layout = () => {
  const token = sessionStorage.getItem("token");

  let authenticatedRoutes = useRoutes([routes[1]]);
  let UnauthenticatedRoutes = useRoutes([routes[0]]);
  const element = token ? authenticatedRoutes : UnauthenticatedRoutes;

  return <div>{element}</div>;
};

export default Layout;
