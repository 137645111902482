import axios from "./index";

export const createClientRequest = async (reqObj) => {
  return await axios.post("/admin/create/client", reqObj);
};

export const getClientRequest = async (reqObj) => {
  return await axios.get(`/admin/get-client/${reqObj || "-"}`);
};

export const getTaskRequest = async (reqObj) => {
  return await axios.get(`/admin/get-jobs/-/${reqObj || "-"}`);
};

export const createTaskRequest = async (reqObj) => {
  return await axios.post("/admin/create/job", reqObj);
};

export const updateClientRequest = async (reqObj) => {
  return await axios.put("/admin/update/client", reqObj);
};
