import { Navigate, Outlet } from "react-router-dom";
import NewInvoiceForm from "../components/Invoice/NewInvoiceForm";
import CreateRequisitionForm from "../components/Requisition/CreateRequisitionForm";
import AuthenticationLayout from "../view/Authentication";
import Login from "../view/Authentication/Login";
import Signup from "../view/Authentication/Signup";
import MainLayout from "../view/Pages";
import Dashboard from "../view/Pages/Dashboard";
import Task from "../view/Pages/Dashboard/Task";
import InvoiceManagement from "../view/Pages/InvoiceManagement";
import VendorInvoiceRequest from "../view/Pages/InvoiceManagement/VendorInvoiceRequests";
import ProfileManagement from "../view/Pages/Profile";
import RequisitionManagement from "../view/Pages/RequisitionManagement";
import VendorItems from "../view/Pages/VendorItems";

const routes = [
  {
    path: "/",
    element: <AuthenticationLayout />,
    children: [
      { path: "/", element: <Login /> },
      { path: "signup", element: <Signup /> },
      { path: "/", element: <Navigate to="/" /> },
      { path: "login", element: <Navigate to="/" /> },
      { path: "*", element: <Navigate to="/" /> },
    ],
  },
  {
    path: "/",
    element: <MainLayout />,
    children: [
      { path: "/", element: <Dashboard /> },
      { path: ":id", element: <Task /> },
      {
        path: "invoice",
        element: <Outlet />,
        children: [
          { path: "/", element: <InvoiceManagement /> },
          { path: ":add-invoice", element: <NewInvoiceForm /> },
          { path: "invoice-requests", element: <VendorInvoiceRequest /> },
        ],
      },
      {
        path: "requisition",
        element: <Outlet />,
        children: [
          { path: "/", element: <RequisitionManagement /> },
          { path: ":create-requisition", element: <CreateRequisitionForm /> },
          { path: "*", element: <RequisitionManagement /> },
        ],
      },
      { path: "vendor-items", element: <VendorItems /> },
      { path: "profile", element: <ProfileManagement /> },
      { path: "client", element: <Navigate to="/" /> },
      { path: "*", element: <Navigate to="/" /> },
    ],
  },
];

export default routes;
