import axios from "axios";
import { logoutRequest } from "./AuthRequest";

const DEVELOPMENT = process.env.NODE_ENV;

const productionBaseURL = "https://accounting.touchandpay.me:1443/v1";
const stagingBaseURL = "https://tfmngr.touchandpay.me:1443/v1";
// const stagingBaseURL = "https://localhost:5001/v1";

const baseURL =
  DEVELOPMENT === "development" ? stagingBaseURL : productionBaseURL;

const axiosInstance = axios.create({
  baseURL,
});

axiosInstance.interceptors.request.use((config) => {
  const token = sessionStorage.getItem("token");

  if (token) {
    config.headers = {
      Authorization: `bearer ${token}`,
      "Content-Type": "Application/json",
    };
  }
  return config;
});

axiosInstance.interceptors.response.use((response) => {
  // to trigger automatic logout if the auth is invalid
  if (response?.data?.error?.message == "The authentication is invalid") {
    logoutRequest();
    window.location.pathname = "/";
    // console.log("refreshed");
  }
  return response;
});

export default axiosInstance;
