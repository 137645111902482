import React, { useState } from "react";
import { useLocation } from "react-router-dom";

import { Button, Typography } from "antd";
import { MailOutlined, PhoneFilled } from "@ant-design/icons";
import { useQuery } from "react-query";
import { getTaskRequest } from "../../../api/ClientRequest";
import TaskList from "../../../components/TaskList";
import { NotificationHandler } from "../../../util";
import NewTaskForm from "../../../components/NewTaskForm";

const { Title } = Typography;

function Task() {
  // const { state } = useLocation();
  const clientRecord = JSON.parse(localStorage.getItem("clientID"));
  const [isAssignTaskFormVisible, setAssignTaskFormVisible] = useState(false);
  const {
    data: response,
    isLoading,
    refetch,
  } = useQuery("tasks", () => getTaskRequest(clientRecord.id), {
    onError: (error) => {
      NotificationHandler("error", error.message);
    },
    retry: false,
  });

  const handleAssignTask = () => {
    setAssignTaskFormVisible((prev) => !prev);
  };

  return (
    <div className="my-4">

      <div className="flex justify-between items-center mb-8 ">
        <div>
          <Title level={2} style={{ color: "#ec7c30", marginBottom: 5 }}>
            {clientRecord?.clientName}
          </Title>

          <p className="text-secondary-black mt-0 pt-0 flex items-center inline-block">
            <MailOutlined /> &nbsp; {clientRecord?.contactEmail} &nbsp;
          </p>
          <p className="text-secondary-black mt-0 pt-0 flex items-center  inline-block">
            <PhoneFilled /> &nbsp; {clientRecord?.contactPhone}

          </p>
        </div>

        <div>
          <Button
            type="primary"
            size="middle"
            className="my-4"
            onClick={handleAssignTask}
          >
            Assign Task
          </Button>
        </div>
      </div>

      {isAssignTaskFormVisible && (
        <NewTaskForm
          toggleAssignTaskFormVisible={handleAssignTask}
          refetch={refetch}
        />
      )}

      <TaskList tasks={response?.data?.content?.data} loading={isLoading} />
    </div>
  );
}

export default Task;
