import React, { useState } from "react";
import { useQueries } from "react-query";
import { useNavigate } from "react-router-dom";
import { Button, Space, message, Switch } from "antd";
import PageTitle from "../../../components/PageTitle";
import { NotificationHandler } from "../../../util";
import MoneyCardList from "../../../components/MoneyCardList";
import FilterMoneyCardList from "../../../components/Invoice/FilterMoneyCardList";
import { getClientRequest, getTaskRequest } from "../../../api/ClientRequest";
import { getRequisitionRequest } from "../../../api/RequisitionRequest";
import NewRequisitionForm from "../../../components/Requisition/NewRequisitionForm";
import MoneyCardSummary from "../../../components/MoneyCardSummary";
import RequisitionTableListView from "../../../components/Requisition/RequisitionTableListView";

function RequisitionManagement() {
  const navigate = useNavigate();
  const [filterParam, setFilterParam] = useState(null);
  const [isMoneyCardView, setIsMoneyCardView] = useState(true);
  const [isNewReqFormVisible, setIsNewReqFormVisible] = useState(false);
  const [
    { data: requisition, isLoading, error, refetch, isIdle },
    { data: clients },
    { data: tasks },
  ] = useQueries([
    {
      queryKey: ["requisition", filterParam],
      queryFn: () => getRequisitionRequest(filterParam),
      onError: (error) => {
        NotificationHandler("error", error.message);
      },
      retry: false,
      refetchOnWindowFocus: false,
    },
    {
      queryKey: "clients",
      queryFn: () => getClientRequest(),
      onError: (error) => {
        NotificationHandler("error", error.message);
        message.error(error.message);
      },
      retry: false,
      refetchOnWindowFocus: false,
    },
    {
      queryKey: "jobs",
      queryFn: () => getTaskRequest(),
      refetchOnWindowFocus: false,
      retry: false,
    },
  ]);

  const tasksData = tasks?.data?.content?.data;
  const clientsData = clients?.data?.content?.data;
  const requisitionData = requisition?.data?.content;

  const handleNewReqClient = () => {
    setIsNewReqFormVisible((prev) => !prev);
  };

  const filterRequisition = (filterParams) => {
    setFilterParam(filterParams);
  };

  const handleSwitchDataView = () => {
    setIsMoneyCardView(!isMoneyCardView);
  };
  return (
    <div>
      <div className="flex flex-wrap justify-between">
        <div>
          <PageTitle title="Manage Requisitions" />

          <Space size="middle">
            {/* /requisitions/:create-requisition points to <CreateRequisitionForm /> */}
            <Button
              type="default"
              onClick={() => navigate("/requisition/create-requisition")}
            >
              New Requisition
            </Button>

            <FilterMoneyCardList
              title="Requisition"
              filterRequisition={filterRequisition}
              isLoading={isLoading}
              tasks={tasksData}
              clients={clientsData}
            />
          </Space>
          <div className="mt-4">
            <Switch
              checkedChildren="Card View"
              unCheckedChildren="List View"
              onChange={handleSwitchDataView}
              defaultChecked
            />
          </div>
        </div>

        <MoneyCardSummary title="requisition" summary={requisitionData} />
      </div>

      {isMoneyCardView ? (
        <MoneyCardList
          title="Requisition"
          invoiceData={requisition?.data}
          loading={isLoading}
          clients={clientsData}
          tasks={tasksData}
          refetch={refetch}
        />
      ) : (
        <RequisitionTableListView
          title="Requisition"
          dataSource={requisition?.data?.content?.data}
          loading={isLoading}
          clients={clientsData}
          tasks={tasksData}
          refetch={refetch}
        />
      )}
    </div>
  );
}

export default RequisitionManagement;
