import { Input, Button, InputNumber, Form, Space, Spin } from "antd";
import Modal from "antd/lib/modal/Modal";
import Title from "antd/lib/typography/Title";
import React from "react";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { updateInvoicePaymentRequest } from "../../api/InvoiceRequest";
import { updateRequisitionPaymentRequest } from "../../api/RequisitionRequest";
import { NotificationHandler } from "../../util";

const validateMessages = {
  required: "${label} is required!",
  types: {
    email: "${label} is not a valid email!",
    number: "${label} is not a valid number!",
  },
  number: {
    range: "${label} must be between ${min} and ${max}",
  },
};

const UpdatePaymentModal = ({
  visible,
  cancel,
  invoice,
  title,
  // startWatcher,
  refetch,
}) => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const mutation = useMutation(
    title === "Requisition"
      ? updateRequisitionPaymentRequest
      : updateInvoicePaymentRequest,
    {
      onSuccess: (data, variables, context) => {
        const { data: response } = data;
        if (response?.error?.status === 1) {
          NotificationHandler("error", response.error.message);
        } else {
          NotificationHandler("success", response?.success?.message);
          // startWatcher();
          refetch();
          cancel();
          form.resetFields();
          navigate(`/${title.toLowerCase()}`);
        }
      },
      onError: (error, variables, context) => {
        NotificationHandler("error", error.message);
      },
    }
  );

  const handleUpdatePayment = (values) => {
    const reqObj = {
      data: {
        id: invoice.id,
        invoiceID: invoice?.invoiceID,
        paymentHistory: [{ ...values }],
      },
    };

    mutation.mutate(reqObj);
  };
  return (
    <Modal visible={visible} onCancel={cancel} footer={[]}>
      <Title level={4}>Update Payment</Title>

      <Form
        layout="vertical"
        name="nest-messages"
        onFinish={handleUpdatePayment}
        validateMessages={validateMessages}
        style={{ marginTop: 24 }}
        form={form}
      >
        <Form.Item
          name={"amount"}
          label="Amount"
          rules={[{ type: "number", required: true }]}
        >
          <InputNumber style={{ width: "100%" }} />
        </Form.Item>
        <Form.Item
          name={"beneficiaryAccount"}
          label="Beneficiary Account"
          rules={[{ required: true }]}
        >
          <Input style={{ width: "100%" }} type="number" />
        </Form.Item>
        <Form.Item name={"note"} label="Details" initialValue="">
          <Input.TextArea />
        </Form.Item>
        <Form.Item>
          <Space size="middle">
            <Button type="default" onClick={cancel}>
              Cancel
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              disabled={mutation.isLoading}
            >
              Update
            </Button>
            {mutation.isLoading && <Spin />}
          </Space>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default UpdatePaymentModal;
