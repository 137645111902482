import {
  Form,
  Row,
  Col,
  Button,
  Spin,
  Select,
  message,
  Input,
  Checkbox,
} from 'antd'
import { useMutation, useQueries, useQueryClient } from 'react-query'
import { useLocation, useNavigate } from 'react-router-dom'
import { createInvoiceRequest } from '../../api/InvoiceRequest'
import { getClientRequest, getTaskRequest } from '../../api/ClientRequest'
import { NotificationHandler, sortArrayBy } from '../../util'
import FormLineItemDynamic from '../FormLineItemDynamic'
import PageTitle from '../PageTitle'
import { useEffect, useState } from 'react'

const validateMessages = {
  required: '${label} is required!',
  types: {
    email: '${label} is not a valid email!',
    number: '${label} is not a valid number!',
  },
}

const { Option } = Select

const NewInvoiceForm = () => {
  const navigate = useNavigate()
  const { state } = useLocation()
  const [isInvoiceRequest, setIsInvoiceRequest] = useState(false)
  const [form] = Form.useForm()
  const [{ data: clients }, { data: tasks }] = useQueries([
    {
      queryKey: 'clients',
      queryFn: () => getClientRequest(),
      onError: (error) => {
        NotificationHandler('error', error.message)
        message.error(error.message)
      },
      retry: false,
      refetchOnWindowFocus: false,
    },
    {
      queryKey: 'jobs',
      queryFn: () => getTaskRequest(),
    },
  ])

  const mutation = useMutation(createInvoiceRequest, {
    onSuccess: (data, variables, context) => {
      const { data: response } = data
      if (response?.error?.status === 1) {
        NotificationHandler('error', response.error.message)
      } else {
        setIsInvoiceRequest(false)
        NotificationHandler('success', response?.success?.message)
        navigate('/invoice')
      }
    },
    onError: (error, variables, context) => {
      NotificationHandler('error', error.message)
    },
  })

  const clientsList = clients?.data?.content?.data
  const tasksList = tasks?.data?.content?.data

  useEffect(() => {
    if (state) {
      setIsInvoiceRequest(true)
      form.setFieldsValue({
        clientID: state.invoice.clientID,
        requestID: state.id,
      })
    }
  }, [state])

  const onFinish = (values) => {
    // console.log('valiues sss', values)
    delete values.requestID
    mutation.mutate(values)
  }

  return (
    <div>
      <PageTitle title="New Invoice" />

      <Form
        layout="vertical"
        name="nest-messages"
        form={form}
        onFinish={onFinish}
        validateMessages={validateMessages}
        className="mt-6"
      >
        <Row gutter={24} className="my-4">
          <Col xs={24} md={12}>
            <Form.Item
              name="clientID"
              label="Client Name"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select placeholder="Select Client" allowClear>
                {clientsList !== undefined &&
                  clientsList.length > 0 &&
                  sortArrayBy(clientsList, 'clientName').map((item, index) => (
                    <Option value={item.id}> {item.clientName} </Option>
                  ))}
                {/* <Option value={0}>Client Name</Option>
                <Option value={1}>Client Name two</Option> */}
              </Select>
            </Form.Item>
          </Col>

          <Col xs={24} md={12}>
            <Form.Item
              name="jobID"
              label="Task Name"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select placeholder="Select Task" allowClear>
                {tasksList !== undefined &&
                  tasksList.length > 0 &&
                  tasksList.map((item, index) => {
                    let clientName
                    if (clientsList !== undefined) {
                      clientName = clientsList.map((clientItem) => {
                        if (clientItem.id == item.clientID) {
                          return clientItem.clientName
                        }
                      })
                    }

                    return (
                      <Option value={item.id}>
                        {item.jobName} <strong>for</strong> {clientName}
                      </Option>
                    )
                  })}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        {/* {inputList.map((item, index) => (
        <FormLineItem
          item={item}
          idx={index}
          form={Form}
          thisForm={form}
          handleAddLineItem={handleAddLineItem}
          handleRemoveLineItem={handleRemoveLineItem}
          values={inputList}
        />
      ))} */}
        <FormLineItemDynamic
          form={Form}
          title="lineItems"
          invoiceData={state?.invoice}
          _form={form}
        />

        {/* When converting Invoice Request to New Invoice, 
        id of InvoiceRequest is set to requestID and 
        immediateDebit set to true to outrightly debit the customer  */}
        {/* Start of hidden fields */}
        <Form.Item
          name={'requestID'}
          label="Request Name"
          hidden
          initialValue={state?.id || ''}
        >
          <Input />
        </Form.Item>

        {/* show this checkbox only if you're trying to convert vendor invoice request to Final Invoice */}
        <Form.Item
          name={'immediateDebit'}
          hidden={!isInvoiceRequest}
          valuePropName="checked"
        >
          <Checkbox>Debit Customer Immediately</Checkbox>
        </Form.Item>

        {/* End of Hidden Fields */}

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            disabled={mutation.isLoading}
          >
            Submit
          </Button>
          {mutation.isLoading && <Spin />}
        </Form.Item>
      </Form>
    </div>
  )
}

export default NewInvoiceForm
