import { Table, message, Button, Row, Col } from "antd";
import React, { useState } from "react";
import { useQuery } from "react-query";
import { getProfileRequest } from "../../../api/ProfileRequest";
import PageTitle from "../../../components/PageTitle";
import NewStaffForm from "../../../components/Profile/NewStaffForm";
import UserCard from "../../../components/Profile/UserCard";
import { getAccessLevel, NotificationHandler } from "../../../util";

function ProfileManagement() {
  const userProfile = JSON.parse(sessionStorage.getItem("userProfile"));
  const [isNewProfileFormVisible, setIsNewProfileFormVisible] = useState(false);
  const {
    data: response,
    isLoading,
    refetch,
  } = useQuery("profile", () => getProfileRequest(), {
    onError: (error) => {
      NotificationHandler("error", error.message);
    },
    onSuccess: ({ data }) => {
      if (data.error.status === 1) {
        // NotificationHandler("error", data.error.message);
        message.error(data.error.message, 10);
      }
    },
  });

  const columns = [
    {
      title: "Name",
      dataIndex: "fullname",
      fixed: "left",
    },
    {
      title: "Email",
      dataIndex: "email",
    },
    {
      title: "Phone",
      dataIndex: "phone",
    },

    {
      title: "Gender",
      dataIndex: "gender",
    },
    {
      title: "Access Level",
      dataIndex: "accessLevel",
      render: (text, record) => <p> {getAccessLevel(record.accessLevel)} </p>,
    },
    {
      title: "Designation",
      dataIndex: "designation",
    },
    // {
    //   title: "Action",
    //   dataIndex: "action",
    //   render: (text, record) => (
    //     <Space>
    //       <Button>Update Profile</Button>
    //     </Space>
    //   ),
    // },
  ];

  const handleNewProfile = () => {
    setIsNewProfileFormVisible((prev) => !prev);
  };

  return (
    <div>
      <PageTitle title="Manage Profile" />

      <Row gutter={16}>
        <Col xl={8}>
          <UserCard userProfile={userProfile} refetch={refetch} />
        </Col>
        <Col xl={16}>
          {/* Only give access to create staff to SuperAdmin (0) and Supervisor (1)  */}
          {userProfile.accessLevel === 0 || userProfile.accessLevel === 1 ? (
            <>
              <Button
                type="primary"
                size="large"
                className="mb-4"
                onClick={handleNewProfile}
              >
                Create Staff
              </Button>

              {isNewProfileFormVisible && (
                <NewStaffForm
                  toggleIsNewProfileForm={handleNewProfile}
                  refetch={refetch}
                />
              )}

              <Table
                columns={columns}
                dataSource={response?.data?.content?.staffs}
                loading={isLoading}
                scroll={{ x: 550 }}
                sticky
              />
            </>
          ) : null}
        </Col>
      </Row>
    </div>
  );
}

export default ProfileManagement;
