import { Input, Button, Form, Space, Spin, Checkbox } from "antd";
import Modal from "antd/lib/modal/Modal";
import Title from "antd/lib/typography/Title";
import React from "react";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import {
  forwardInvoiceToMailRequest,
  forwardReceiptToMailRequest,
} from "../../api/InvoiceRequest";
import { NotificationHandler } from "../../util";

const validateMessages = {
  required: "${label} is required!",
  types: {
    email: "${label} is not a valid email!",
    number: "${label} is not a valid number!",
  },
  number: {
    range: "${label} must be between ${min} and ${max}",
  },
};

const ForwardToMailModal = ({
  visible,
  cancel,
  invoice,
  title,
  refetch,
  docType,
}) => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const mutation = useMutation(
    docType == "invoice"
      ? forwardInvoiceToMailRequest
      : forwardReceiptToMailRequest,
    {
      onSuccess: (data, variables, context) => {
        const { data: response } = data;
        if (response?.error?.status === 1) {
          NotificationHandler("error", response.error.message);
        } else {
          NotificationHandler("success", "Mail Sent!");
          // startWatcher();
          refetch();
          cancel();
          form.resetFields();
          navigate(`/${title.toLowerCase()}`);
        }
      },
      onError: (error, variables, context) => {
        NotificationHandler("error", error.message);
      },
    }
  );

  const handleSendToMail = (values) => {
    const reqObj = {
      data: {
        invoiceID: invoice?.invoiceID,
        ...values,
      },
    };

    mutation.mutate(reqObj);
  };
  return (
    <Modal visible={visible} onCancel={cancel} footer={[]}>
      <Title level={4}>Forward Invoice</Title>

      <Form
        layout="vertical"
        name="nest-messages"
        onFinish={handleSendToMail}
        validateMessages={validateMessages}
        style={{ marginTop: 24 }}
        form={form}
      >
        <Form.Item name={"emails"} label="Emails">
          <Input.TextArea placeholder="Example: abc@mail.com, def@mail.com, gcd@mail.com etc..." />
        </Form.Item>
        <Form.Item name="includeClient" valuePropName="checked">
          <Checkbox>Forward to Client Emails</Checkbox>
        </Form.Item>
        <Form.Item>
          <Space size="middle">
            <Button type="default" onClick={cancel}>
              Cancel
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              //   disabled={mutation.isLoading}
            >
              Send
            </Button>
            {mutation.isLoading && <Spin />}
          </Space>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ForwardToMailModal;
