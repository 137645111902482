import axios from "./index";

export const createStaffRequest = async (reqObj) => {
  return await axios.post("/admin/create/staff", reqObj);
};

export const getProfileRequest = async () => {
  return await axios.get(`/admin/get-staff`);
};

export const updateProfileRequest = async (reqObj) => {
  return await axios.put("/admin/update/self", reqObj);
};

export const updatePasswordRequest = async (reqObj) => {
  return await axios.put("/admin/update/password", reqObj);
};
