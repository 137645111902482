import React, { useEffect } from "react";
import { Input, InputNumber, Select, Row, Col, Tooltip, Button } from "antd";
import { MinusCircleOutlined } from "@ant-design/icons";

const { Option } = Select;

const FormLineItemDynamic = ({
  form: Form,
  title,
  action,
  _form,
  invoiceData,
}) => {
  useEffect(() => {
    // const { lineItems } = _form.getFieldsValue();
    if (invoiceData?.lineItems) {
      // Object.assign(lineItems, invoiceData?.lineItems);
      const lineItems = invoiceData.lineItems;
      _form.setFieldsValue({ lineItems });
    }
  }, [invoiceData]);
  return (
    <Form.List name={title}>
      {(fields, { add, remove }) => (
        <>
          {/* {fields.map((field) => (
            <Row gutter={12} key={fields.field.key}>
              <Col span={12} xs={24} md={4}>
                <Form.Item
                  {...field}
                  label="Item Name"
                  name={[field.name, "name"]}
                  fieldKey={[field.fieldKey, "name"]}
                  rules={[
                    {
                      required: true,
                      message: "Item name is required",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>

              <Col span={12} xs={24} md={4}>
                <Form.Item
                  {...field}
                  name={[field.name, "valueType"]}
                  fieldKey={[field.fieldKey, "valueType"]}
                  label="Value Type"
                  rules={[
                    {
                      required: true,
                      message: "Value Type is required!",
                    },
                  ]}
                >
                  <Select placeholder="Select Type" allowClear>
                    <Option title="valueType" value={0}>
                      Flat Rate
                    </Option>
                    <Option title="valueType" value={1}>
                      Percentage
                    </Option>
                  </Select>
                </Form.Item>
              </Col>

              <Col span={12} xs={24} md={4}>
                <Form.Item
                  {...field}
                  name={[field.name, "unitValue"]}
                  fieldKey={[field.fieldKey, "unitValue"]}
                  label="Unit Value"
                  rules={[
                    {
                      type: "number",
                      required: true,
                    },
                  ]}
                >
                  <InputNumber style={{ width: "100%" }} />
                </Form.Item>
              </Col>

              <Col span={12} xs={24} md={4}>
                <Form.Item
                  {...field}
                  name={[field.name, "unit"]}
                  fieldKey={[field.fieldKey, "unit"]}
                  label="Unit"
                  rules={[
                    {
                      type: "number",
                      required: true,
                    },
                  ]}
                >
                  <InputNumber style={{ width: "100%" }} />
                </Form.Item>
              </Col>

              <Col span={12} xs={24} md={4}>
                <Form.Item
                  {...field}
                  name={[field.name, "valueOperation"]}
                  fieldKey={[field.fieldKey, "valueOperation"]}
                  label="Value Operation"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Select placeholder="Select Operation" allowClear>
                    <Option value={1}>Add Item</Option>
                    <Option value={2}>Minus Item</Option>
                  </Select>
                </Form.Item>
              </Col>

              <Col span={12} xs={24} md={4} style={{ position: "relative" }}>
                <Form.Item
                  {...field}
                  name={[field.name, "details"]}
                  fieldKey={[field.fieldKey, "details"]}
                  label="Details"
                  // rules={[
                  //   {
                  //     required: true,
                  //   },
                  // ]}
                >
                  <Input />
                </Form.Item>
                <div
                  style={{
                    position: "absolute",
                    right: 10,
                    top: 0,
                    zIndex: 100,
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    remove(field.name);
                  }}
                >
                  <Tooltip title="Remove Item">
                    <MinusCircleOutlined />
                  </Tooltip>
                </div>
              </Col>
            </Row>
          ))} */}

          {fields.map((field) => (
            <Row gutter={12} key={field.key}>
              <Col span={12} xs={24} md={4}>
                <Form.Item
                  {...field}
                  label="Item Name"
                  name={[field.name, "name"]}
                  fieldKey={[field.fieldKey, "name"]}
                  rules={[
                    {
                      required: true,
                      message: "Item name is required",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>

              <Col span={12} xs={24} md={4}>
                <Form.Item
                  {...field}
                  name={[field.name, "valueType"]}
                  fieldKey={[field.fieldKey, "valueType"]}
                  label="Value Type"
                  rules={[
                    {
                      required: true,
                      message: "Value Type is required!",
                    },
                  ]}
                >
                  <Select placeholder="Select Type" allowClear>
                    <Option title="valueType" value={0}>
                      Flat Rate
                    </Option>
                    <Option title="valueType" value={1}>
                      Percentage
                    </Option>
                  </Select>
                </Form.Item>
              </Col>

              <Col span={12} xs={24} md={4}>
                <Form.Item
                  {...field}
                  name={[field.name, "unitValue"]}
                  fieldKey={[field.fieldKey, "unitValue"]}
                  label="Unit Value"
                  rules={[
                    {
                      type: "number",
                      required: true,
                    },
                  ]}
                >
                  <InputNumber style={{ width: "100%" }} />
                </Form.Item>
              </Col>

              <Col span={12} xs={24} md={4}>
                <Form.Item
                  {...field}
                  name={[field.name, "unit"]}
                  fieldKey={[field.fieldKey, "unit"]}
                  label="Unit"
                  rules={[
                    {
                      type: "number",
                      required: true,
                    },
                  ]}
                >
                  <InputNumber style={{ width: "100%" }} />
                </Form.Item>
              </Col>

              <Col span={12} xs={24} md={4}>
                <Form.Item
                  {...field}
                  name={[field.name, "valueOperation"]}
                  fieldKey={[field.fieldKey, "valueOperation"]}
                  label="Value Operation"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Select placeholder="Select Operation" allowClear>
                    <Option value={1}>Add Item</Option>
                    <Option value={2}>Minus Item</Option>
                  </Select>
                </Form.Item>
              </Col>

              <Col span={12} xs={24} md={4} style={{ position: "relative" }}>
                <Form.Item
                  {...field}
                  name={[field.name, "details"]}
                  fieldKey={[field.fieldKey, "details"]}
                  label="Details"
                  // rules={[
                  //   {
                  //     required: true,
                  //   },
                  // ]}
                >
                  <Input />
                </Form.Item>
                <div
                  style={{
                    position: "absolute",
                    right: 10,
                    top: 0,
                    zIndex: 100,
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    remove(field.name);
                  }}
                >
                  <Tooltip title="Remove Item">
                    <MinusCircleOutlined />
                  </Tooltip>
                </div>
              </Col>
            </Row>
          ))}
          <Form.Item>
            <Button type="default" onClick={() => add()}>
              Add Item
            </Button>
          </Form.Item>
        </>
      )}
    </Form.List>
  );
};

export default FormLineItemDynamic;
