import React from "react";
import { useNavigate } from "react-router-dom";
import { useMutation } from "react-query";
import { Form, Input, Button, Typography, Spin } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";

import { Card } from "antd";
import { loginRequest } from "../../api/AuthRequest";
import { NotificationHandler, setAuthorization } from "../../util";
const { Title } = Typography;

const Login = () => {
  const navigate = useNavigate();
  const mutation = useMutation(loginRequest, {
    onSuccess: (data, variables, context) => {
      const { data: response } = data;
      if (response.error.status === 1) {
        NotificationHandler("error", response.error.message);
      } else {
        const userData = response.content;
        setAuthorization(userData);
        NotificationHandler("success", response.success.message);
        navigate("/");
      }
    },
    onError: (error, variables, context) => {
      NotificationHandler("error", error.message);
    },
  });

  const onFinish = async (values) => {
    // console.log("Received values of form: ", values);
    mutation.mutate(values);
  };

  return (
    <div className="w-full h-screen flex items-center justify-center ">
      <Card className="py-5 border-2 " style={{ width: "350px" }}>

        <Title level={3} style={{ color: "#e06615 " }} className="pb-4">

          Login here
        </Title>
        <Form
          name="normal_login"
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
        >
          <Form.Item
            name="username"
            normalize={(value) => {
              return (value || "").toLowerCase().trim();
            }}
            rules={[
              {
                required: true,
                message: "Please input your Username!",
              },
            ]}
          >
            <Input
              prefix={<UserOutlined className="site-form-item-icon" />}
              placeholder="Username"
            />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: "Please input your Password!",
              },
            ]}
          >
            <Input
              prefix={<LockOutlined className="site-form-item-icon" />}
              type="password"
              placeholder="Password"
            />
          </Form.Item>

          <Form.Item className="mt-3">
            <Button
              type="primary"
              htmlType="submit"
              disabled={mutation.isLoading}
            >
              Log in
            </Button>
            {mutation.isLoading && <Spin className="ml-2" />}
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
};

export default Login;
