import { Table, Tooltip } from "antd";
import Modal from "antd/lib/modal/Modal";
import React from "react";
import moment from "moment";
import { formatMoney } from "../../util";
import Title from "antd/lib/typography/Title";
import { ExpandAltOutlined } from "@ant-design/icons";

const PaymentHistoryModal = ({ visible, cancel, paymentHistory }) => {
  const columns = [
    {
      title: "Beneficiary Account",
      dataIndex: "beneficiaryAccount",
    },
    {
      title: "Amount Paid",
      dataIndex: "amount",
      align: "right",
      render: (value) => <p> {formatMoney(value)} </p>,
    },
    {
      title: "Date Paid",
      dataIndex: "datePaid",
      render: (value) => <p>{moment(value * 1000).format("DD/MM/YYYY")}</p>,
    },
    {
      title: "Note",
      dataIndex: "note",
      align: "right",
      render: (value) => (
        <Tooltip title={value || "None available"}>
          <ExpandAltOutlined />
        </Tooltip>
      ),
    },
  ];
  return (
    <Modal visible={visible} onCancel={cancel} onOk={cancel}>
      <Title className="mt-5 " level={4}>
        Payment History
      </Title>
      <div>
        {Boolean(paymentHistory.length) && (
          <Table columns={columns} dataSource={paymentHistory} />
        )}
      </div>
      <div>
        {" "}
        {paymentHistory.length === 0 && <p>No payment has been made.</p>}{" "}
      </div>
    </Modal>
  );
};

export default PaymentHistoryModal;
