import { Form, Input, InputNumber, Button, Spin } from "antd";
import { useMutation } from "react-query";
import { createVendorItemRequest } from "../../../../api/VendorItemRequest";
import { NotificationHandler } from "../../../../util";

const layout = {
  labelCol: {
    span: 5,
  },
  wrapperCol: {
    span: 12,
  },
};

const validateMessages = {
  required: "${label} is required!",
  types: {
    email: "${label} is not a valid email!",
    number: "${label} is not a valid number!",
  },
  number: {
    range: "${label} must be between ${min} and ${max}",
  },
};

const AddVendorItemForm = ({ toggleIsVendorItemFormVisible, refetch }) => {
  const mutation = useMutation(createVendorItemRequest, {
    onSuccess: (data, variables, context) => {
      const { data: response } = data;
      if (response?.error?.status === 1) {
        NotificationHandler("error", response.error.message);
      } else {
        NotificationHandler("success", response?.success?.message);
        refetch();
        toggleIsVendorItemFormVisible();
      }
    },
    onError: (error, variables, context) => {
      NotificationHandler("error", error.message);
    },
  });

  const onFinish = (values) => {
    mutation.mutate(values);
  };

  return (
    <Form
      {...layout}
      name="nest-messages"
      onFinish={onFinish}
      validateMessages={validateMessages}
    >
      <Form.Item
        name={["data", "itemName"]}
        label="Item Name"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name={["data", "value"]}
        label="Value"
        rules={[
          {
            type: "number",
            required: true,
          },
        ]}
      >
        <InputNumber style={{ width: "100%" }} />
      </Form.Item>
      <Form.Item
        name={["data", "stock"]}
        label="Available Stock"
        rules={[
          {
            type: "number",
            required: true,
          },
        ]}
      >
        <InputNumber style={{ width: "100%" }} />
      </Form.Item>

      <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 8 }}>
        <Button type="primary" htmlType="submit" disabled={mutation.isLoading}>
          Submit
        </Button>
        {mutation.isLoading && <Spin />}
      </Form.Item>
    </Form>
  );
};

export default AddVendorItemForm;
