import { Form, Input, Button, Spin, Select, InputNumber } from "antd";
import { useMutation } from "react-query";
import { useLocation } from "react-router-dom";
import { createRequisitionRequest } from "../../api/RequisitionRequest";
import { NotificationHandler } from "../../util";

const layout = {
  labelCol: {
    span: 6,
  },
  wrapperCol: {
    span: 12,
  },
};

const validateMessages = {
  required: "${label} is required!",
  types: {
    email: "${label} is not a valid email!",
    number: "${label} is not a valid number!",
  },
  number: {
    range: "${label} must be between ${min} and ${max}",
  },
};

const { Option } = Select;

const NewRequisitionForm = ({ toggleNewReqFormVisible, clients, tasks }) => {
  const { state } = useLocation();
  const mutation = useMutation(createRequisitionRequest, {
    onSuccess: (data, variables, context) => {
      const { data: response } = data;
      if (response?.error?.status === 1) {
        NotificationHandler("error", response.error.message);
      } else {
        NotificationHandler("success", response?.success?.message);
        toggleNewReqFormVisible();
      }
    },
    onError: (error, variables, context) => {
      NotificationHandler("error", error.message);
    },
  });

  const onFinish = (values) => {
    mutation.mutate(values);
  };

  return (
    <>
      <Form
        {...layout}
        name="nest-messages"
        onFinish={onFinish}
        validateMessages={validateMessages}
      >
        <Form.Item
          className="my-0"
          wrapperCol={{ ...layout.wrapperCol, offset: 6 }}
        >
          <p className="text-xl my-0">Create Requisition</p>
        </Form.Item>
        <Form.Item name={["data", "clientID"]} label="Client Name">
          <Select placeholder="Select Client" allowClear>
            {clients &&
              clients.map((item, index) => (
                <Option value={item.id} key={index}>
                  {" "}
                  {item.clientName}{" "}
                </Option>
              ))}
          </Select>
        </Form.Item>
        <Form.Item name={["data", "jobID"]} label="Task Name">
          <Select placeholder="Select Task" allowClear>
            {tasks &&
              tasks.map((item, index) => {
                let clientName;
                if (clients !== undefined) {
                  clientName = clients.map((clientItem) => {
                    if (clientItem.id == item.clientID) {
                      return clientItem.clientName;
                    }
                  });
                }

                return (
                  <Option value={item.id}>
                    {item.jobName} <strong>for</strong> {clientName}
                  </Option>
                );
              })}
          </Select>
        </Form.Item>
        <Form.Item
          name={["data", "name"]}
          label="Name"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name={["data", "amount"]}
          label="Amount"
          rules={[{ type: "number", required: true }]}
        >
          <InputNumber style={{ width: "100%" }} />
        </Form.Item>

        <Form.Item name={["data", "details"]} label="Details">
          <Input.TextArea placeholder="Details... (Optional)" />
        </Form.Item>
        <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 8 }}>
          <Button
            type="primary"
            htmlType="submit"
            disabled={mutation.isLoading}
          >
            Submit
          </Button>
          {mutation.isLoading && <Spin />}
        </Form.Item>
      </Form>
    </>
  );
};

export default NewRequisitionForm;
