import React from "react";
import { Outlet } from "react-router-dom";

import Navbar from "../../components/Navbar";
import { LogoutOutlined } from "@ant-design/icons";

function MainLayout() {
  return (
    <div>
      <Navbar
        children={[
          "client",
          "invoice",
          "requisition",
          "vendor-Items",
          "profile",
        ]}
        icon={<LogoutOutlined />}
      />
      <div className="p-2 md:py-3 md:px-32 mt-16 ">
        <Outlet />
      </div>
    </div>
  );
}

export default MainLayout;
