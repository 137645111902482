import { Tooltip } from "antd";
import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { logoutRequest } from "../api/AuthRequest";

function Navbar({ children, icon }) {
  const navigate = useNavigate();
  const userProfile = JSON.parse(sessionStorage.getItem("userProfile"));
  const [toggleNav, setToggleNav] = useState(false);

  const handleToggleNav = () => {
    setToggleNav((prevState) => !prevState);
  };

  const handleLogout = () => {
    logoutRequest();
    navigate("/");
  };
  return (
    <nav className="bg-primary-blue p-2 md:py-3 md:px-32 flex justify-between items-center sticky-nav">
      <NavLink
        to="/"
        className="text-primary-white text-xl font-bold leading-loose"
      >
        TAP Finance
      </NavLink>
      <ul className={`nav-menu ${toggleNav ? "active" : ""}`}>
        {children?.map((item, index) => (
          <li className="mr-5 text-primary-white nav-item" key={index}>
            <NavLink
              end
              to={`/${item === "login" || item === "client" ? "" : `${item}`}`}
              activeClassName="nav-link-active"
              className="nav-link"
              onClick={() => setToggleNav(false)}
            >
              {item.charAt(0).toUpperCase() + item.slice(1)}
            </NavLink>
          </li>
        ))}
        {icon && (
          <li
            className="mr-5 text-primary-white cursor-pointer mt-0 nav-item"
            onClick={handleLogout}
          >
            <Tooltip placement="bottom" title="Logout" className="nav-link">
              <span> {userProfile.username} </span>
              {icon}
            </Tooltip>
          </li>
        )}
      </ul>
      <div
        className={`hamburger ${toggleNav ? "active" : ""}`}
        onClick={handleToggleNav}
      >
        <span className="bar"></span>
        <span className="bar"></span>
        <span className="bar"></span>
      </div>
    </nav>
  );
}

export default Navbar;
