import React, { useEffect, useState } from "react";
import { UserAddOutlined } from "@ant-design/icons";
import { Button, Space, Table, message } from "antd";
import NewClientForm from "../../../components/NewClientForm";
import { getClientRequest } from "../../../api/ClientRequest";
import { useQuery } from "react-query";
import { formatMoney, NotificationHandler } from "../../../util";
import { useNavigate } from "react-router-dom";
import PageTitle from "../../../components/PageTitle";
import EditClientModal from "../../../components/Dashboard/EditClientModal";
import { getDashboardRequest } from "../../../api/DashboardRequest";

function Dashboard() {
  const navigate = useNavigate();
  const {
    data: response,
    isLoading,
    refetch,
  } = useQuery("clients", () => getClientRequest("-"), {
    onError: (error) => {
      NotificationHandler("error", error.message);
    },
    onSuccess: ({ data }) => {
      if (data.error.status === 1) {
        // NotificationHandler("error", data.error.message);
        message.error(data.error.message, 10);
      }
    },
  });

  const [isEditClientVisible, setIsEditClientVisible] = useState(false);
  const [clientRecord, setClientRecord] = useState(null);
  const [isOnboardFormVisible, setIsOnboardFormVisible] = useState(false);
  const columns = [
    {
      title: "Name",
      dataIndex: "clientName",
      fixed: "left",
      width: 100,
    },
    {
      title: "Phone",
      dataIndex: "contactPhone",
    },
    {
      title: "Email",
      dataIndex: "contactEmail",
    },
    {
      title: "Balance",
      dataIndex: "balance",
      render: (text, record) => formatMoney(record.balance),
    },
    {
      title: "Allowed Minimum Balance",
      dataIndex: "allowableMinimumBalance",
      render: (text, record) => formatMoney(record.allowableMinimumBalance),
    },
    {
      title: "Address",
      dataIndex: "address",
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (text, record) => (
        <Space>
          {/* redirects to <Task /> onclick */}
          <Button
            onClick={() => {
              localStorage.setItem("clientID", JSON.stringify(record));
              return navigate(`/details`, { state: record });
            }}
          >
            View / Assign Task
          </Button>
          <Button onClick={() => openEditClientModal(record)}>
            {" "}
            Update Client
          </Button>
        </Space>
      ),
    },
  ];

  const handleOnboardClient = () => {
    setIsOnboardFormVisible((prev) => !prev);
  };

  const openEditClientModal = (record) => {
    setIsEditClientVisible(true);
    setClientRecord(record);
  };

  const handleCloseEditClientModal = () => {
    setIsEditClientVisible(false);
  };

  useEffect(() => {
    getDashboardRequest();
  }, []);

  return (
    <>
      <EditClientModal
        visible={isEditClientVisible}
        cancel={handleCloseEditClientModal}
        title="Update Client"
        clientRecord={clientRecord}
        refetch={refetch}
      />
      <div className="py-1 md:py-4">
        <PageTitle title="Manage Client" />
        <Button
          type="primary"
          icon={<UserAddOutlined />}
          size="large"
          className="mb-4"
          onClick={handleOnboardClient}
        >
          Onboard Client
        </Button>

        {isOnboardFormVisible && (
          <NewClientForm
            toggleIsOnboardFormVisible={handleOnboardClient}
            refetch={refetch}
          />
        )}

        <div className="table-size">
          <Table
            columns={columns}
            loading={isLoading}
            dataSource={response?.data?.content?.data}
            // scroll={{ x: 500 }}
            // sticky
          />
        </div>
      </div>
    </>
  );
}

export default Dashboard;
