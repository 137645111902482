import axios from "./index";

export const getVendorItemsRequest = async () => {
  return await axios.get(`/admin/get-item`);
};

export const createVendorItemRequest = async (reqObj) => {
  return await axios.post("/admin/create/item", reqObj);
};

export const updateVendorItemRequest = async (reqObj) => {
  return await axios.put("/admin/update/item", reqObj);
};
