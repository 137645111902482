import React, { useState } from "react";
import { UserAddOutlined } from "@ant-design/icons";
import { Button, Space, Table, message } from "antd";
import NewClientForm from "../../../components/NewClientForm";
import { getClientRequest } from "../../../api/ClientRequest";
import { useQuery } from "react-query";
import { formatMoney, NotificationHandler } from "../../../util";
import { useNavigate } from "react-router-dom";
import PageTitle from "../../../components/PageTitle";
import EditClientModal from "../../../components/Dashboard/EditClientModal";
import { getVendorItemsRequest } from "../../../api/VendorItemRequest";
import AddVendorItemForm from "./components/AddVendorItem";
import EditVendorItemModal from "./components/EditVendorItem";

function VendorItems() {
  const navigate = useNavigate();
  const {
    data: response,
    isLoading,
    refetch,
  } = useQuery("vendorItems", () => getVendorItemsRequest("-"), {
    onError: (error) => {
      NotificationHandler("error", error.message);
    },
    onSuccess: ({ data }) => {
      if (data.error.status === 1) {
        // NotificationHandler("error", data.error.message);
        message.error(data.error.message, 10);
      }
    },
  });

  const [vendorItemRecord, setVendorItemRecord] = useState(null);
  const [isEditVendorItemVisible, setIsEditVendorItemVisible] = useState(false);
  const [isAddVendorItemFormVisible, setIsAddVendorItemFormVisible] =
    useState(false);
  const columns = [
    {
      title: "Name",
      dataIndex: "itemName",
      fixed: "left",
      // width: 100,
    },
    {
      title: "Unit Value",
      dataIndex: "value",
      render: (text, record) => formatMoney(record.value),
      align: "right",
    },
    {
      title: "Stock Available",
      dataIndex: "stock",
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (text, record) => (
        <Space>
          <Button onClick={() => openEditVendorItemModal(record)}>
            {" "}
            Update Item
          </Button>
        </Space>
      ),
    },
  ];

  const handleAddVendorItem = () => {
    setIsAddVendorItemFormVisible((prev) => !prev);
  };

  const openEditVendorItemModal = (record) => {
    setIsEditVendorItemVisible(true);
    setVendorItemRecord(record);
  };

  const handleCloseEditVendorItemModal = () => {
    setIsEditVendorItemVisible(false);
  };

  return (
    <>
      {/* <EditClientModal
        visible={isEditClientVisible}
        cancel={handleCloseEditVendorItemModal}
        title="Update Client"
        clientRecord={clientRecord}
        refetch={refetch}
      /> */}
      <EditVendorItemModal
        visible={isEditVendorItemVisible}
        cancel={handleCloseEditVendorItemModal}
        title="Update Vendor Item"
        vendorItemRecord={vendorItemRecord}
        refetch={refetch}
      />
      <div className="py-1 md:py-4">
        <PageTitle title="Vendor Items" />
        <Button
          type="primary"
          icon={<UserAddOutlined />}
          size="large"
          className="mb-4"
          onClick={handleAddVendorItem}
        >
          Add Item
        </Button>

        {isAddVendorItemFormVisible && (
          <AddVendorItemForm
            toggleIsVendorItemFormVisible={handleAddVendorItem}
            refetch={refetch}
          />
        )}

        <div className="table-size">
          <Table
            columns={columns}
            loading={isLoading}
            dataSource={response?.data?.content?.data}
            // scroll={{ x: 500 }}
            // sticky
          />
        </div>
      </div>
    </>
  );
}

export default VendorItems;
