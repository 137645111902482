import React from "react";
import { Outlet } from "react-router-dom";
import Navbar from "../../components/Navbar";

function AuthenticationLayout() {
  return (
    <div>
      <Navbar children={["login"]} />
      <div>
        <Outlet />
      </div>
    </div>
  );
}

export default AuthenticationLayout;
