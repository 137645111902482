import moment from "moment";
import { formatDate } from "../util";
import axios from "./index";

export const createInvoiceRequest = async (reqObj) => {
  const newInvoice = {
    data: {
      ...reqObj,
    },
  };
  return await axios.post("/admin/create/invoice", newInvoice);
};

export const getInvoiceRequest = async (filterParams) => {
  let fromDate, toDate, id, jobID, status, clientID;
  if (filterParams !== undefined && filterParams !== null) {
    fromDate = filterParams?.dateFrom && formatDate(filterParams?.dateFrom?._d);
    toDate = filterParams?.toDate && formatDate(filterParams?.toDate?._d);
    id = filterParams?.id;
    jobID = filterParams?.jobID;
    status = filterParams?.status;
    clientID = filterParams?.clientID;
  } else {
    fromDate = moment().subtract(1, "d").unix() * 1000 || "0";
    toDate = new Date().getTime();
  }

  const now = new Date().getTime();
  return await axios.get(
    `/admin/get-invoice/${fromDate}/${toDate || now}/${clientID || "-"}/${
      id || "-"
    }/-/${jobID || "-"}/${(status == 0 ? "0" : status) || "-"}`
  );
};

export const updateInvoicePaymentRequest = async (reqObj) => {
  return await axios.put("/admin/update/invoice/payment", reqObj);
};

export const forwardInvoiceToMailRequest = async (reqObj) => {
  return await axios.put("/admin/sendInvoice", reqObj);
};

export const forwardReceiptToMailRequest = async (reqObj) => {
  return await axios.put("/admin/sendPayment", reqObj);
};

export const updateInvoiceRequest = async (reqObj) => {
  const newInvoice = {
    data: {
      ...reqObj,
    },
  };
  return await axios.put("/admin/update/invoice/items", newInvoice);
};

export const getInvoiceTableRequest = async (filterParams) => {
  let fromDate, toDate, id, jobID, status, clientID;
  if (filterParams !== undefined && filterParams !== null) {
    fromDate = filterParams?.dateFrom && formatDate(filterParams?.dateFrom?._d);
    toDate = filterParams?.toDate && formatDate(filterParams?.toDate?._d);
    id = filterParams?.id;
    jobID = filterParams?.jobID;
    status = filterParams?.status;
    clientID = filterParams?.clientID;
  } else {
    toDate = new Date().getTime();
  }

  const now = new Date().getTime();
  return await axios.get(
    `/admin/get-lineitems/${fromDate || "0"}/${toDate || now}/${
      clientID || "-"
    }/${id || "-"}/-/${jobID || "-"}/${status || "-"}`
  );
};

// VENDOR
export const getVendorInvoiceRequest = async () => {
  return await axios.get(`/admin/get/invoice-request`);
};
