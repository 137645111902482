import React from "react";
import { Button, Table } from "antd";
import moment from "moment";
import { CSVLink } from "react-csv";
import { DownloadOutlined } from "@ant-design/icons";
import {
  addSerialNumberToList,
  formatMoney,
  getInvoiceStatus,
} from "../../util";

const headers = [
  {
    label: "S/N",
    key: "sn",
  },
  {
    label: "Invoice Name",
    key: "name",
  },
  {
    label: "Client Name",
    key: "clientName",
  },
  {
    label: "Unit Value",
    key: "unitValue",
  },
  {
    label: "Unit",
    key: "unit",
  },
  {
    label: "Date Generated",
    key: "dateGenerated",
  },
  {
    label: "Details",
    key: "details",
  },
];

function RequisitionTableListView({ dataSource, clients, tasks, title }) {
  let result = {};
  if (dataSource) {
    for (let i = 0; i < dataSource.length; i++) {
      for (let j = 0; j < clients.length; j++) {
        if (dataSource[i].clientID === clients[j].id) {
          result[dataSource[i].id] = clients[j];
        }
      }
    }
  }

  const RequisitionTableHeader = [
    {
      title: "S/N",
      dataIndex: "sn",
    },
    {
      title: "Name",
      dataIndex: "name",
    },

    {
      title: "Client Name",
      dataIndex: "clientName",
      render: (text, record) => result[record.id].clientName,
    },
    {
      title: `${title} ID`,
      dataIndex: "id",
    },

    {
      title: "Invoice Value",
      dataIndex: "invoiceValue",
      align: "right",
      render: (text, record) => <p> {formatMoney(record.invoiceValue)}</p>,
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (text, record) => (
        <p className="bg-yellow-100 text-yellow-700 status">
          {getInvoiceStatus(record.status)}
        </p>
      ),
    },
    {
      title: "Details",
      dataIndex: "details",
      render: (text, record) => <p> {record.details || "Nil"}</p>,
    },

    {
      title: "Date Generated",
      dataIndex: "dateCreated",
      render: (text, record) => {
        if (title == "Invoice Request")
          return moment(record.dateCreated).format("Do MMMM, YYYY");
        return moment(record.dateCreated * 1000).format("Do MMMM, YYYY");
      },
    },
  ];

  const expandedRow = (breakdown) => {
    const columns = [
      {
        title: "Name",
        dataIndex: "name",
        fixed: "left",
      },
      {
        title: "Unit Value",
        dataIndex: "unitValue",
        align: "right",
        render: (value, record) => {
          if (record.valueType === 0) {
            return <p> {formatMoney(value)} </p>;
          } else {
            return <p> {value}% </p>;
          }
        },
      },
      {
        title: "Unit",
        dataIndex: "unit",
        align: "right",
      },
      {
        title: "Note",
        dataIndex: "details",
        align: "right",
        render: (value) => <p> {value || "Nil"} </p>,
      },
    ];

    return (
      <Table
        columns={columns}
        dataSource={breakdown}
        pagination={false}
        style={{ width: "60%", textAlign: "center", margin: "16px" }}
        size="small"
        className="table-striped-rows"
      />
    );
  };

  return (
    <div className="mt-4">
      <div className="table-size">
        <Table
          columns={RequisitionTableHeader}
          dataSource={addSerialNumberToList(dataSource)}
          expandable={{
            expandedRowRender: (record) => {
              return expandedRow(record?.breakdown || record?.lineItems);
            },
          }}
        />
      </div>
    </div>
  );
}

export default RequisitionTableListView;
