import { notification } from "antd";
import moment from "moment";

export const NotificationHandler = (type, desc) => {
  return (
    <div>
      {notification[type]({
        message: type,
        description: desc,
      })}
    </div>
  );
};

export const setAuthorization = (userData) => {
  const { token, profile } = userData;
  sessionStorage.setItem("token", token);
  sessionStorage.setItem("userProfile", JSON.stringify(profile));
};

export const formatMoney = (value) => {
  return value.toLocaleString("en-NG", { style: "currency", currency: "NGN" });
};

export const getInvoiceStatus = (value) => {
  switch (value) {
    case 0: {
      return "PENDING";
    }
    case 1: {
      return "APPROVED";
    }
    case 2: {
      return "PAID PARTIAL";
    }
    case 3: {
      return "FULLY PAID";
    }
    case 10: {
      return "FORWARDED";
    }
    case -1: {
      return "REJECTED";
    }
  }
};

export const STATUS = [
  { key: 0, message: "Pending" },
  { key: 1, message: "Approved" },
  { key: 2, message: "Partial Payment" },
  { key: 3, message: "Paid Fully" },
  { key: 10, message: "Forwarded" },
  { key: -1, message: "Rejected" },
];

export const getAccessLevel = (value) => {
  switch (value) {
    case 0: {
      return "Admin";
    }
    case 1: {
      return "Supervisor";
    }
    case 2: {
      return "Staff";
    }
    case 3: {
      return "Others";
    }
  }
};

export const formatDate = (date) => {
  const newDate = new Date(date).getTime() / 1000;
  return newDate;
};

export const formatDateMoment = (date) => {
  return moment(date * 1000).format("Do MMMM, YYYY");
};

export const addSerialNumberToList = (list) => {
  if (list)
    return list.map((item, index) => ({
      ...item,
      sn: index + 1,
      key: index + 1,
    }));
};

export const processTableListForExport = (list) => {
  if (list && list !== undefined)
    return list.map((item, index) => ({
      ...item,
      valueType: item.valueType === 0 ? "Flat Rate" : "Percentage",
      valueOperation: item.valueOperation === 1 ? "Add Item" : "Minus Item",
      unitValue: formatMoney(item.unitValue),
      dateGenerated: formatDateMoment(item.dateGenerated),
      parentInvoiceStatus: getInvoiceStatus(item.parentInvoiceStatus),
      note: item.note ? item.note : "None available",
      sn: index + 1,
    }));
};

export const sortArrayBy = (arr, name) => {
  if (name) return arr.sort((a, b) => a[name].localeCompare(b[name]));
  return arr.sort();
};
