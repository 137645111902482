
import { BrowserRouter } from "react-router-dom";
import Layout from "./routes";

function App() {
  return (
    <BrowserRouter>
      <Layout />
    </BrowserRouter>

  );
}

export default App;
