import { Input, Button, InputNumber, Form, Space, Spin, Select } from "antd";
import Modal from "antd/lib/modal/Modal";
import Title from "antd/lib/typography/Title";
import React from "react";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { updateProfileRequest } from "../../api/ProfileRequest";
import { NotificationHandler } from "../../util";

const validateMessages = {
  required: "${label} is required!",
  types: {
    email: "${label} is not a valid email!",
    number: "${label} is not a valid number!",
  },
  number: {
    range: "${label} must be between ${min} and ${max}",
  },
};

const { Option } = Select;

const UpdateProfileModal = ({ visible, cancel, userProfile, refetch }) => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const mutation = useMutation(updateProfileRequest, {
    onSuccess: (data, variables, context) => {
      const { data: response } = data;
      if (response?.error?.status === 1) {
        NotificationHandler("error", response.error.message);
      } else {
        NotificationHandler("success", response?.success?.message);
        refetch();
        cancel();
        form.resetFields();
        //   navigate(`/${title.toLowerCase()}`);
      }
    },
    onError: (error, variables, context) => {
      NotificationHandler("error", error.message);
    },
  });

  const handleUpdateProfile = (values) => {
    mutation.mutate(values);
  };

  const initialValues = [
    {
      name: ["data", "fullname"],
      value: userProfile.fullname,
    },
    {
      name: ["data", "email"],
      value: userProfile.email,
    },
    {
      name: ["data", "phone"],
      value: userProfile.phone,
    },
    {
      name: ["data", "username"],
      value: userProfile.username,
    },
  ];
  return (
    <Modal visible={visible} onCancel={cancel} footer={[]}>
      <Title level={4}>Update Profile</Title>

      <Form
        layout="vertical"
        name="nest-messages"
        onFinish={handleUpdateProfile}
        validateMessages={validateMessages}
        style={{ marginTop: 24 }}
        form={form}
        fields={initialValues}
        // fields={[
        //   {
        //     name: ["data", "fullname"],
        //     value: userProfile.fullname,
        //   },
        // ]}
      >
        <Form.Item
          name={["data", "fullname"]}
          label="Fullname"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name={["data", "username"]}
          label="Username"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name={["data", "email"]}
          label="Email"
          rules={[
            {
              type: "email",
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name={["data", "phone"]}
          label="Phone"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input style={{ width: "100%" }} type="number" />
        </Form.Item>

        {/* <Form.Item
          name={["data", "gender"]}
          label="Gender"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select placeholder="Select Gender">
            <Option value="Female">Female</Option>
            <Option value="Male">Male</Option>
          </Select>
        </Form.Item> */}

        <Form.Item>
          <Space size="middle">
            <Button type="default" onClick={cancel}>
              Cancel
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              disabled={mutation.isLoading}
            >
              Update
            </Button>
            {mutation.isLoading && <Spin />}
          </Space>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default UpdateProfileModal;
