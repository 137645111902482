import React from "react";
import { Typography } from "antd";

const { Title } = Typography;
function PageTitle({ title }) {
  return (
    <div>
      <Title level={2} style={{ color: "#ec7c30", marginBottom: 25 }}>
        {title}
      </Title>
    </div>
  );
}

export default PageTitle;
