import React, { useEffect } from "react";
import { Button, message, Table } from "antd";
import { useQuery } from "react-query";
import { getInvoiceTableRequest } from "../api/InvoiceRequest";
import {
  addSerialNumberToList,
  formatDate,
  formatMoney,
  getInvoiceStatus,
  NotificationHandler,
  processTableListForExport,
} from "../util";
import moment from "moment";
import { CSVLink } from "react-csv";
import { DownloadOutlined } from "@ant-design/icons";

const headers = [
  {
    label: "S/N",
    key: "sn",
  },
  {
    label: "Invoice Name",
    key: "name",
  },
  {
    label: "Client Name",
    key: "clientName",
  },
  {
    label: "Unit Value",
    key: "unitValue",
  },
  {
    label: "Unit",
    key: "unit",
  },
  {
    label: "Status",
    key: "parentInvoiceStatus",
  },
  {
    label: "Date Generated",
    key: "dateGenerated",
  },
  {
    label: "Details",
    key: "details",
  },
  {
    label: "Note",
    key: "note",
  },
];

function MoneyTableList({ title, tableFilterParam, handleTableSummary }) {
  const { data: response, isLoading } = useQuery(
    ["inv", tableFilterParam],
    () => getInvoiceTableRequest(tableFilterParam),
    {
      onError: (error) => {
        NotificationHandler("error", error.message);
      },
      onSuccess: ({ data }) => {
        if (data.error.status === 1) {
          // NotificationHandler("error", data.error.message);
          message.error(data.error.message, 10);
        }
      },
    }
  );

  const columns = [
    {
      title: "S/N",
      dataIndex: "sn",
    },
    {
      title: "Invoice Name",
      dataIndex: "name",
      fixed: "left",
      //   width: 100,
    },
    {
      title: "Client Name",
      dataIndex: "clientName",
      fixed: "left",
      //   width: 100,
    },
    {
      title: "Unit Value",
      dataIndex: "unitValue",
      align: "right",
      render: (text, record) =>
        record.valueType === 0
          ? formatMoney(record.unitValue)
          : record.unitValue + "%",
    },

    {
      title: "Unit",
      dataIndex: "unit",
      align: "right",
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (text, record) => (
        <p className="bg-yellow-100 text-yellow-700 status">
          {getInvoiceStatus(record.parentInvoiceStatus)}
        </p>
      ),
    },
    {
      title: "Date Generated",
      dataIndex: "dateGenerated",
      render: (text, record) =>
        moment(record.dateGenerated * 1000).format("Do MMMM, YYYY"),
    },
    {
      title: "Details",
      dataIndex: "details",
      render: (text, record) => (record.details ? record.details : "Nil"),
    },
    {
      title: "Note",
      dataIndex: "note",
      render: (text, record) => (record.note ? record.note : "Nil"),
    },
  ];

  useEffect(() => {
    handleTableSummary(response);
  }, [response]);

  const serialledData = addSerialNumberToList(response?.data?.content?.data);

  const csvExportData = processTableListForExport(
    response?.data?.content?.data
  );
  return (
    <div className="mt-4">
      <Button
        type="primary"
        icon={<DownloadOutlined />}
        size="middle"
        className="mb-4"
      >
        <CSVLink headers={headers} data={csvExportData || [{}]}>
          Download List
        </CSVLink>
      </Button>

      <div className="table-size">
        <Table
          columns={columns}
          loading={isLoading}
          dataSource={serialledData}
          // scroll={{ x: 500 }}
          // sticky
        />
      </div>
    </div>
  );
}

export default MoneyTableList;
