import { Card } from "antd";
import React from "react";
import TaskCard from "./TaskCard";

function TaskList({ tasks, loading }) {
  if (tasks && tasks.length === 0) {
    return <div>No task is assigned to this client...</div>;
  }
  return (

    <div className="my-8 grid lg:grid-cols-3 gap-3 items-end   ">
      {tasks &&
        tasks.map((task, idx) => (
          <div
            key={idx}
            // style={{ display: "inline-block" }}
          >
            <TaskCard task={task} loading={loading} />
          </div>
        ))}

      {loading && <Card style={{ width: 400 }} loading={loading}></Card>}
    </div>
  );
}

export default TaskList;
