import { Table } from "antd";
import Modal from "antd/lib/modal/Modal";
import React from "react";
import moment from "moment";
import Title from "antd/lib/typography/Title";
import { getInvoiceStatus } from "../util";

const MoneyCardDetailsModal = ({ visible, cancel, title, data }) => {
  const details = () => {
    if (data?.status == 1 || data?.status == 2 || data?.status == 3) {
      return (
        <div className="mt-2">
          <h4 className="text-xl">
            {" "}
            Status: {getInvoiceStatus(data?.status)}{" "}
          </h4>
          <p>
            {" "}
            {title} was approved by {data?.approvedBy} on{" "}
            {moment(data?.dateApproved * 1000).format("Do MMMM, YYYY")}{" "}
          </p>
          {data?.processedBy && (
            <p>
              This {title} was processed by {data?.processedBy} on{" "}
              {moment(data?.dateProccesed * 1000).format("Do MMMM, YYYY")}{" "}
            </p>
          )}
        </div>
      );
    }
  };

  return (
    <Modal visible={visible} onCancel={cancel} onOk={cancel}>
      <Title className="mt-5 " level={4}>
        {title} Details
      </Title>
      <div>
        <p>
          Created by {data?.creator} on{" "}
          {moment((data?.dateGenerated || data?.dateCreated) * 1000).format(
            "Do MMMM, YYYY"
          )}{" "}
        </p>
      </div>
      <div>{details()}</div>
    </Modal>
  );
};

export default MoneyCardDetailsModal;
