import React from "react";

function Signup() {
  return (
    <div>
      <h1>Signup Here</h1>
    </div>
  );
}

export default Signup;
