import { Form, Input, InputNumber, Button, Spin } from "antd";
import { useMutation } from "react-query";
import { createClientRequest } from "../api/ClientRequest";
import { NotificationHandler } from "../util";

const layout = {
  labelCol: {
    span: 5,
  },
  wrapperCol: {
    span: 12,
  },
};

const validateMessages = {
  required: "${label} is required!",
  types: {
    email: "${label} is not a valid email!",
    number: "${label} is not a valid number!",
  },
  number: {
    range: "${label} must be between ${min} and ${max}",
  },
};

const NewClientForm = ({ toggleIsOnboardFormVisible, refetch }) => {
  const mutation = useMutation(createClientRequest, {
    onSuccess: (data, variables, context) => {
      const { data: response } = data;
      if (response?.error?.status === 1) {
        NotificationHandler("error", response.error.message);
      } else {
        NotificationHandler("success", response?.success?.message);
        refetch();
        toggleIsOnboardFormVisible();
      }
    },
    onError: (error, variables, context) => {
      NotificationHandler("error", error.message);
    },
  });

  const onFinish = (values) => {
    mutation.mutate(values);
  };

  return (
    <Form
      {...layout}
      name="nest-messages"
      onFinish={onFinish}
      validateMessages={validateMessages}
    >
      <Form.Item
        name={["data", "clientName"]}
        label="Client Name"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name={["data", "contactEmail"]}
        label="Email"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input.TextArea
          placeholder={`Include client emails separated by comma\n Eg. hello@tap.com, admin@tap.com, ask@tap.com etc`}
        />
      </Form.Item>
      <Form.Item
        name={["data", "contactPhone"]}
        label="Client Phone"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input style={{ width: "100%" }} type="number" />
      </Form.Item>

      <Form.Item
        name={["data", "address"]}
        label="Address"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input.TextArea />
      </Form.Item>

      <Form.Item
        name={["data", "allowableMinimum"]}
        label="Allowable Minimum"
        rules={[
          {
            type: "number",
            required: true,
          },
        ]}
      >
        <InputNumber style={{ width: "100%" }} />
      </Form.Item>

      <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 8 }}>
        <Button type="primary" htmlType="submit" disabled={mutation.isLoading}>
          Submit
        </Button>
        {mutation.isLoading && <Spin />}
      </Form.Item>
    </Form>
  );
};

export default NewClientForm;
