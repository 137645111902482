import { Form, Input, InputNumber, Button, Spin, Select, message } from "antd";
import { useMutation, useQueries } from "react-query";
import { getClientRequest } from "../../api/ClientRequest";
import { createStaffRequest } from "../../api/ProfileRequest";
import { NotificationHandler, sortArrayBy } from "../../util";

const layout = {
  labelCol: {
    span: 5,
  },
  wrapperCol: {
    span: 12,
  },
};

const validateMessages = {
  required: "${label} is required!",
  types: {
    email: "${label} is not a valid email!",
    number: "${label} is not a valid number!",
  },
  number: {
    range: "${label} must be between ${min} and ${max}",
  },
};

const { Option } = Select;

const NewStaffForm = ({ toggleIsNewProfileForm, refetch }) => {
  const [{ data: clients }] = useQueries([
    {
      queryKey: "clients",
      queryFn: () => getClientRequest(),
      onError: (error) => {
        NotificationHandler("error", error.message);
        message.error(error.message);
      },
      retry: false,
      refetchOnWindowFocus: false,
    },
  ]);

  const mutation = useMutation(createStaffRequest, {
    onSuccess: (data, variables, context) => {
      const { data: response } = data;
      if (response?.error?.status === 1) {
        NotificationHandler("error", response.error.message);
      } else {
        NotificationHandler("success", response?.success?.message);
        refetch();
        toggleIsNewProfileForm();
      }
    },
    onError: (error, variables, context) => {
      NotificationHandler("error", error.message);
    },
  });

  const clientsList = clients?.data?.content?.data;

  const onFinish = (values) => {
    // console.log(values);
    mutation.mutate(values);
  };

  return (
    <Form
      {...layout}
      name="nest-messages"
      onFinish={onFinish}
      validateMessages={validateMessages}
    >
      <Form.Item
        name={["data", "fullname"]}
        label="Fullname"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name={["data", "username"]}
        label="Username"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name={["data", "email"]}
        label="Email"
        rules={[
          {
            type: "email",
            required: true,
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name={["data", "phone"]}
        label="Phone"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input type="number" style={{ width: "100%" }} />
      </Form.Item>

      <Form.Item
        name={["data", "clientID"]}
        label="Client Name"
        // rules={[
        //   {
        //     required: true,
        //   },
        // ]}
      >
        <Select placeholder="Select Client">
          {clientsList !== undefined &&
            clientsList.length > 0 &&
            sortArrayBy(clientsList, "clientName").map((item, index) => (
              <Option value={item.id}> {item.clientName} </Option>
            ))}
        </Select>
      </Form.Item>

      <Form.Item
        name={["data", "password"]}
        label="Password"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input type="password" />
      </Form.Item>

      <Form.Item
        name={["data", "confirmPassword"]}
        label="Confirm Password"
        dependencies={["data", "password"]}
        rules={[
          {
            required: true,
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue(["data", "password"]) === value) {
                return Promise.resolve();
              }
              return Promise.reject(
                new Error("The two passwords that you entered do not match!")
              );
            },
          }),
        ]}
        hasFeedback
      >
        <Input type="password" />
      </Form.Item>

      <Form.Item
        name={["data", "accessLevel"]}
        label="Access Level"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Select placeholder="Select Access Level">
          <Option value={0}>Admin</Option>
          <Option value={1}>Supervisor</Option>
          <Option value={2}>Staff</Option>
          <Option value={3}>Others</Option>
        </Select>
      </Form.Item>

      <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 8 }}>
        <Button type="primary" htmlType="submit" disabled={mutation.isLoading}>
          Submit
        </Button>
        {mutation.isLoading && <Spin />}
      </Form.Item>
    </Form>
  );
};

export default NewStaffForm;
