import React, { useState } from "react";
import { useQueries } from "react-query";
import { useNavigate } from "react-router-dom";
import { Button, Space, message, Switch } from "antd";
import { getVendorInvoiceRequest } from "../../../api/InvoiceRequest";
import { NotificationHandler } from "../../../util";
import { getClientRequest, getTaskRequest } from "../../../api/ClientRequest";
import PageTitle from "../../../components/PageTitle";
import FilterMoneyCardList from "../../../components/Invoice/FilterMoneyCardList";
import MoneyCardSummary from "../../../components/MoneyCardSummary";
import MoneyCardList from "../../../components/MoneyCardList";
import MoneyTableList from "../../../components/MoneyTableList";
import RequisitionTableListView from "../../../components/Requisition/RequisitionTableListView";

function VendorInvoiceRequest() {
  const [filterParam, setFilterParam] = useState(null);
  const [tableFilterParam, setTableFilterParam] = useState(null);
  const [isMoneyCardView, setIsMoneyCardView] = useState(true);
  const [tableListResponse, setTableListResponse] = useState(null);

  const [
    { data: invoice, isLoading, error, refetch, isIdle },
    { data: clients },
    { data: tasks },
  ] = useQueries([
    {
      queryKey: ["invoice-request", filterParam],
      queryFn: () => getVendorInvoiceRequest(filterParam),
      onError: (error) => {
        NotificationHandler("error", error.message);
      },
      retry: false,
      refetchOnWindowFocus: false,
      // enabled: !!filterParam,
    },
    {
      queryKey: "clients",
      queryFn: () => getClientRequest(),
      onError: (error) => {
        NotificationHandler("error", error.message);
        message.error(error.message);
      },
      retry: false,
      refetchOnWindowFocus: false,
    },
    {
      queryKey: "jobs",
      queryFn: () => getTaskRequest(),
      refetchOnWindowFocus: false,
      retry: false,
    },
  ]);

  const tasksData = tasks?.data?.content?.data;
  const clientsData = clients?.data?.content?.data;
  const invoiceData = invoice?.data?.content;

  const filterInvoice = (filterParams) => {
    setFilterParam(filterParams);
  };

  const filterTableListInvoice = (filterParams) => {
    setTableFilterParam(filterParams);
  };

  const handleSwitchDataView = () => {
    setIsMoneyCardView(!isMoneyCardView);
  };

  const handleTableSummary = (response) => {
    const content = response?.data?.content;
    setTableListResponse(content);
  };

  return (
    <div>
      <div className="flex flex-wrap justify-between">
        <div>
          <PageTitle title="Invoice Request" />
          <div className="mt-4">
            <Switch
              checkedChildren="Card View"
              unCheckedChildren="List View"
              onChange={handleSwitchDataView}
              defaultChecked
            />
          </div>
        </div>

        <MoneyCardSummary title="invoice" summary={invoiceData} />
      </div>

      {isMoneyCardView ? (
        <MoneyCardList
          invoiceData={invoice?.data}
          loading={isLoading}
          title="Invoice"
          clients={clientsData}
          tasks={tasksData}
          refetch={refetch}
          type="invoice-request"
        />
      ) : (
        <RequisitionTableListView
          title="Invoice Request"
          dataSource={invoice?.data?.content?.data}
          loading={isLoading}
          clients={clientsData}
          tasks={tasksData}
          refetch={refetch}
        />
      )}
    </div>
  );
}

export default VendorInvoiceRequest;
