
import { Button, Card, Space } from "antd";
import Title from "antd/lib/typography/Title";
import moment from "moment";
import { useNavigate } from "react-router-dom";

const TaskCard = ({ task, loading }) => {
  const navigate = useNavigate();
  return (
    <Card style={{ borderBottom: "2px solid #ec7c30" }} loading={loading}>

      <div>
        <p className="text-secondary-black">Task Title:</p>
        <Title level={4}> {task.jobName} </Title>
      </div>

      <div>
        <p className="text-secondary-black">Task Details:</p>

        <div style={{ overflow: "hidden" }}>
          {/* <Title level={5}> {task.details} </Title> */}
          <p className="font-xl"> {task.details} </p>
        </div>
      </div>

      <div className="mt-4">

        <span className="footnote-text">
          Created by <strong>{task.creator}</strong> at{" "}
          {moment(task.dateCreated * 1000).format("Do MMMM YYYY")}
        </span>
      </div>


      {/* <div
        className="border-t-2 border-primary-orange"
        style={{
          marginLeft: -25,
          marginRight: -25,
          marginTop: 14,
          // marginBottom: 14,
          textAlign: "right",
        }}
      >
        <Space size="small" className="mt-4 mr-4">
          <Button
            type="default"
            onClick={() => navigate("/invoice", { state: task })}
          >
            Invoice
          </Button>
          <Button
            type="default"
            onClick={() => navigate("/requisitions", { state: task })}
          >
            Requisition
          </Button>
        </Space>
      </div> */}

    </Card>
  );
};

export default TaskCard;
