import { Card } from "antd";
import Title from "antd/lib/typography/Title";
import React from "react";
import { formatMoney } from "../util";

function MoneyCardSummary({ summary, title }) {
  const invoiceSummary = () => (
    <>
      <div>
        <p className="text-secondary-black card-label">Fetched Transactions</p>
        <p>{summary?.count}</p>
      </div>
      <div className="flex justify-between">
        <div>
          <p className="text-secondary-black card-label">Total Invoiced</p>
          <p> {formatMoney(summary?.totalInvoiced || "0")} </p>
        </div>
        <div>
          <p className="text-secondary-black card-label">Total Payment</p>
          <p> {formatMoney(summary?.totalPayment || "0")}</p>
        </div>
      </div>
    </>
  );

  const requisitionSummary = () => (
    <div className="flex justify-between">
      <div>
        <p className="text-secondary-black card-label">Fetched Transactions</p>
        <p>{summary?.count}</p>
      </div>

      <div>
        <p className="text-secondary-black card-label">Total Requisition</p>
        <p> {formatMoney(summary?.totalRequisition || "0")}</p>
      </div>
    </div>
  );

  return (
    <Card
      style={{
        minWidth: "350px",
        borderBottom: "2px solid #ec7c30",
      }}
      className="mt-2 md:mt-0"
    >
      <Title level={4}>Current Summary</Title>
      {title == "invoice" ? invoiceSummary() : requisitionSummary()}
    </Card>
  );
}

export default MoneyCardSummary;
