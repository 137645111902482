import { Card, Pagination } from "antd";
import React, { useEffect, useState } from "react";
import MoneyCardItem from "./MoneyCardItem";

const MoneyCardList = ({
  invoiceData,
  loading,
  title,
  clients,
  tasks,
  refetch,
  type,
}) => {
  const [formattedList, setFormattedList] = useState([]);
  const [list, setList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  useEffect(() => {
    if (invoiceData) {
      const fetchedData = invoiceData?.content?.data;
      const formattedData = chunkArrayByLimit(fetchedData, pageSize);
      setList(formattedData[1] || []);
      setFormattedList(formattedData);
    }
  }, [invoiceData, pageSize]);

  const chunkArrayByLimit = (arr = [], limit = 10) => {
    let result = {}; // map => pageNumber: list
    let count = 1;
    for (let i = 0; i < arr.length; i += limit) {
      result[count] = arr.slice(i, i + limit);
      count++;
    }
    return result;
  };

  const onChangePage = (page) => {
    setCurrentPage(page);
    setList(formattedList[page]);
  };

  const onShowSizeChange = (current, pageSize) => {
    setPageSize(pageSize);
  };

  if (!invoiceData) {
    <p>loading...</p>;
  }

  return (
    <>
      <div className="my-6 grid lg:grid-cols-3 gap-3 items-end ">
        {list.map((item, index) => {
          return (
            <div key={index}>
              <MoneyCardItem
                title={title}
                invoice={item}
                clients={clients}
                tasks={tasks}
                refetch={refetch}
                type={type}
              />
            </div>
          );
        })}
      </div>
      <div>
        {loading && <Card style={{ width: 400 }} loading={loading}></Card>}
      </div>
      <div className="flex items-center justify-center">
        <Pagination
          showSizeChanger
          onShowSizeChange={onShowSizeChange}
          current={currentPage}
          onChange={onChangePage}
          total={invoiceData?.content?.data?.length}
        />
      </div>
    </>
  );
};

export default MoneyCardList;
