import { Input, Button, Form, Space, Spin } from "antd";
import Modal from "antd/lib/modal/Modal";
import Title from "antd/lib/typography/Title";
import React from "react";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { updatePasswordRequest } from "../../api/ProfileRequest";
import { NotificationHandler } from "../../util";

const validateMessages = {
  required: "${label} is required!",
  types: {
    email: "${label} is not a valid email!",
    number: "${label} is not a valid number!",
  },
  number: {
    range: "${label} must be between ${min} and ${max}",
  },
};

const UpdatePasswordModal = ({ visible, cancel, refetch }) => {
  const [form] = Form.useForm();
  const mutation = useMutation(updatePasswordRequest, {
    onSuccess: (data, variables, context) => {
      const { data: response } = data;
      if (response?.error?.status === 1) {
        NotificationHandler("error", response.error.message);
      } else {
        NotificationHandler("success", response?.success?.message);
        refetch();
        cancel();
        form.resetFields();
        //   navigate(`/${title.toLowerCase()}`);
      }
    },
    onError: (error, variables, context) => {
      NotificationHandler("error", error.message);
    },
  });

  const handleUpdatePassword = (values) => {
    // console.log("values", values);
    mutation.mutate(values);
  };

  return (
    <Modal visible={visible} onCancel={cancel} footer={[]}>
      <Title level={4}>Update Password</Title>

      <Form
        layout="vertical"
        name="nest-messages"
        onFinish={handleUpdatePassword}
        validateMessages={validateMessages}
        style={{ marginTop: 24 }}
        form={form}
      >
        <Form.Item
          name={["data", "password"]}
          label="Password"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input type="password" />
        </Form.Item>

        <Form.Item>
          <Space size="middle">
            <Button type="default" onClick={cancel}>
              Cancel
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              disabled={mutation.isLoading}
            >
              Update
            </Button>
            {mutation.isLoading && <Spin />}
          </Space>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default UpdatePasswordModal;
