import axios from "./index";

export const loginRequest = async (obj) => {
  const reqObj = {
    data: {
      ...obj,
    },
  };
  return await axios.post("/admin/login", reqObj);
};

export const logoutRequest = () => {
  sessionStorage.clear();
};
