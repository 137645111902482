import moment from "moment";
import { formatDate } from "../util";
import axios from "./index";

export const createRequisitionRequest = async (reqObj) => {
  const newRequisition = {
    data: {
      ...reqObj,
    },
  };
  return await axios.post("/admin/create/requisition", newRequisition);
};

export const getRequisitionRequest = async (filterParams) => {
  let fromDate, toDate, id, jobID, status, clientID;
  if (filterParams !== undefined && filterParams !== null) {
    fromDate = filterParams?.dateFrom && formatDate(filterParams?.dateFrom?._d);
    toDate = filterParams?.toDate && formatDate(filterParams?.toDate?._d);
    id = filterParams?.id;
    jobID = filterParams?.jobID;
    status = filterParams?.status;
    clientID = filterParams?.clientID;
  } else {
    fromDate = moment().subtract(1, "d").unix() * 1000 || "0";
    toDate = new Date().getTime();
  }

  const now = new Date().getTime();
  return await axios.get(
    `/admin/get-requisition/${fromDate}/${toDate || now}/${clientID || "-"}/${
      id || "-"
    }/-/${jobID || "-"}/${(status == 0 ? "0" : status) || "-"}`
  );
};

export const updateRequisitionPaymentRequest = async (reqObj) => {
  return await axios.put("/admin/update/requisition/payment", reqObj);
};

export const approveRequisitionRequest = async (obj) => {
  const reqObj = {
    data: {
      id: obj,
    },
  };
  // console.log("approve requisition ", reqObj);
  return await axios.put("/admin/approve/requisition", reqObj);
};

export const forwardRequisitionRequest = async (obj) => {
  const reqObj = {
    data: {
      id: obj,
    },
  };
  // console.log("approve requisition ", reqObj);
  return await axios.put("/admin/forward/requisition", reqObj);
};

export const rejectRequisitionRequest = async (obj) => {
  const reqObj = {
    data: {
      id: obj,
    },
  };
  // console.log("approve requisitreion ", reqObj);
  return await axios.put("/admin/reject/requisition", reqObj);
};
