import {
  CheckCircleOutlined,
  EditOutlined,
  ExpandAltOutlined,
  QuestionCircleOutlined,
  SendOutlined,
  StopOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Table,
  Space,
  Popconfirm,
  message,
  Tooltip,
  Spin,
  Popover,
} from "antd";
import Title from "antd/lib/typography/Title";
import React, { useState } from "react";
import { formatMoney, getInvoiceStatus, NotificationHandler } from "../util";
import PaymentHistoryModal from "./Invoice/PaymentHistoryModal";
import UpdatePaymentModal from "./Invoice/UpdatePaymentModal";
import moment from "moment";
import { useMutation, useQuery } from "react-query";
import {
  approveRequisitionRequest,
  forwardRequisitionRequest,
  getRequisitionRequest,
  rejectRequisitionRequest,
} from "../api/RequisitionRequest";
import { getInvoiceRequest } from "../api/InvoiceRequest";
import ForwardToMailModal from "./Invoice/ForwardToMailModal";
import MoneyCardDetailsModal from "./MoneyCardDetailsModal";
import UpdateInvoiceModal from "./Invoice/UpdateInvoiceModal";
import { useNavigate } from "react-router-dom";

const MoneyCardItem = ({ invoice, title, clients, tasks, refetch, type }) => {
  const navigate = useNavigate();
  const [isHistoryModalVisible, setIsHistoryModalVisible] = useState(false);
  const [isUpdateModalVisible, setIsUpdateModalVisible] = useState(false);
  const [isSendMailPopOverVisible, setIsSendMailPopoverVisible] =
    useState(false);
  const [isForwardMailVisible, setIsForwardMailVisible] = useState(false);
  const [docType, setDocType] = useState("");
  const [isDetailsVisible, setIsDetailsVisible] = useState(false);
  const [isUpdateInvoiceVisible, setIsUpdateInvoiceVisible] = useState(false);
  const [invoiceUpdateInfo, setInvoiceUpdateInfo] = useState(false);
  // const [refreshWatcher, setRefreshWatcher] = useState(false);
  const { isIdle, data, status } = useQuery(
    `${title.toLowerCase()} `,
    title === "Requisition"
      ? () => getRequisitionRequest()
      : () => getInvoiceRequest(),
    {
      // enabled: !!refreshWatcher,
      retry: false,
      refetchOnWindowFocus: false,
      // onSuccess: (data) => {
      //   stopWatcher();
      // },
    }
  );
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      fixed: "left",
    },
    {
      title: "Unit Value",
      dataIndex: "unitValue",
      align: "right",
      render: (value, record) => {
        if (record.valueType === 0) {
          return <p> {formatMoney(value)} </p>;
        } else {
          return <p> {value}% </p>;
        }
      },
    },
    {
      title: "Unit",
      dataIndex: "unit",
      align: "right",
    },
    {
      title: "Note",
      dataIndex: "details",
      align: "right",
      render: (value) => (
        <Tooltip title={value || "None available"}>
          <ExpandAltOutlined />
        </Tooltip>
      ),
    },
  ];

  const mutation = useMutation(
    invoice.status == 0 ? forwardRequisitionRequest : approveRequisitionRequest,
    {
      onSuccess: (data, variables, context) => {
        const { data: response } = data;
        if (response?.error?.status === 1) {
          NotificationHandler("error", response.error.message);
        } else {
          // NotificationHandler("success", response?.success?.message);
          // startWatcher();
          refetch();
          message.success(response?.success?.message);
          handleCancel();
        }
      },
      onError: (error, variables, context) => {
        NotificationHandler("error", error.message);
      },
    }
  );

  const { mutate, isLoading } = useMutation(rejectRequisitionRequest, {
    onSuccess: (data, variables, context) => {
      const { data: response } = data;
      if (response?.error?.status === 1) {
        NotificationHandler("error", response.error.message);
      } else {
        // NotificationHandler("success", response?.success?.message);
        // startWatcher();
        refetch();
        message.success(response?.success?.message);
        handleCancel();
      }
    },
    onError: (error, variables, context) => {
      NotificationHandler("error", error.message);
    },
  });

  const showModal = () => {
    setIsHistoryModalVisible(true);
  };

  const closeModal = () => {
    setIsHistoryModalVisible(false);
  };

  const showUpdateModal = () => {
    setIsUpdateModalVisible(true);
  };

  const closeUpdateModal = () => {
    setIsUpdateModalVisible(false);
  };

  const showForwardToMailModal = (value) => {
    setDocType(value);
    setIsSendMailPopoverVisible(false);
    setIsForwardMailVisible(true);
  };

  const showSendMailPopover = () => {
    setIsSendMailPopoverVisible(true);
  };

  const closeForwardToMailModal = () => {
    setIsForwardMailVisible(false);
  };

  const showDetailsModal = () => {
    setIsDetailsVisible(true);
  };

  const closeDetailsModal = () => {
    setIsDetailsVisible(false);
  };

  const showUpdateInvoiceModal = (values) => {
    setInvoiceUpdateInfo(values);
    setIsUpdateInvoiceVisible(true);
  };

  const closeUpdateInvoiceModal = () => {
    setIsUpdateInvoiceVisible(false);
  };

  const handleApproveRequisition = (e) => {
    mutation.mutate(invoice.id);
  };

  const handleForwardRequisition = (e) => {
    mutation.mutate(invoice.id);
  };

  const handleRejectRequisition = (e) => {
    mutate(invoice.id);
  };

  const handleCancel = (e) => {};

  //react-query refetch() method makes this refetchWatcher() redundant

  //the queryCall getInvoice and getRequisition will only fire
  //when the state refreshWatcher is set to true
  //so this initialize watcher will be false before the call, and after one successful call.
  // const stopWatcher = () => {
  //   setRefreshWatcher(false);
  // };

  // // when this state is true, the useQuery will make the api call
  // //basically it triggers the useQuery call
  // const startWatcher = () => {
  //   setRefreshWatcher(true);
  // };

  let client, task;
  if (clients || tasks) {
    client = clients?.find((item) => item.id === invoice.clientID);
    task = tasks?.find(
      (item) => item.id === invoice.jobID && item.clientID === invoice.clientID
    );
  }

  const footerButton = () => {
    if (title === "Requisition") {
      return (
        <>
          {invoice?.status == 0 ? (
            <Popconfirm
              title="Forward requisition to Admin for approval?"
              onConfirm={handleForwardRequisition}
              onCancel={handleCancel}
              okText="Yes"
              cancelText="Cancel"
            >
              <Button style={{ margin: 0 }} key="forward" type="link">
                {mutation.isLoading && <Spin size="small" />}
                Forward
              </Button>
            </Popconfirm>
          ) : (
            <Button
              style={{ margin: 0 }}
              key="updatePayment"
              type="link"
              onClick={showUpdateModal}
            >
              Update Payment
            </Button>
          )}
        </>
      );
    } else if (title === "Invoice") {
      return (
        <Button
          style={{ margin: 0 }}
          key="updatePayment"
          type="link"
          onClick={showUpdateModal}
        >
          Update Payment
        </Button>
      );
    }
  };

  const sendToMailPopOver = () => (
    <div>
      <Space size="middle">
        <Button onClick={() => showForwardToMailModal("invoice")}>
          Send Invoice
        </Button>
        <Button onClick={() => showForwardToMailModal("receipt")}>
          Send Receipt
        </Button>
      </Space>
    </div>
  );

  const _handleUpdateInvoice = (details) => {
    if (type == "invoice-request") {
      navigate("/invoice/add-invoice", {
        state: {
          client: client?.clientName,
          task: task?.jobName,
          id: invoice?.id,
          invoice: invoice,
        },
      });
    } else {
      showUpdateInvoiceModal({
        client: client?.clientName,
        task: task?.jobName,
        id: invoice?.id,
        invoice: invoice,
      });
    }
  };
  return (
    <>
      <PaymentHistoryModal
        visible={isHistoryModalVisible}
        cancel={closeModal}
        paymentHistory={invoice?.paymentHistory}
      />
      <UpdatePaymentModal
        visible={isUpdateModalVisible}
        cancel={closeUpdateModal}
        invoice={invoice}
        title={title}
        // startWatcher={startWatcher}
        refetch={refetch}
      />
      <ForwardToMailModal
        visible={isForwardMailVisible}
        cancel={closeForwardToMailModal}
        invoice={invoice}
        title={title}
        docType={docType}
        // startWatcher={startWatcher}
        refetch={refetch}
      />
      <MoneyCardDetailsModal
        visible={isDetailsVisible}
        cancel={closeDetailsModal}
        data={invoice}
        title={title}
      />
      <UpdateInvoiceModal
        visible={isUpdateInvoiceVisible}
        cancel={closeUpdateInvoiceModal}
        title={title}
        info={invoiceUpdateInfo}
        refetch={refetch}
        // this is necessary to display a dropdown of tasks(or jobs)
        // that can be assigned to a client invoice on update.
        tasksList={tasks}
        clientsList={clients}
      />
      <Card
        className="relative"
        style={{ height: "450px", paddingBottom: 0 }}
        actions={
          type == "invoice-request"
            ? []
            : [
                footerButton(),
                <Button
                  style={{ margin: 0 }}
                  key="paymentHistory"
                  type="link"
                  onClick={showModal}
                >
                  Payment History
                </Button>,
              ]
        }
      >
        <Tooltip title="Details" className="absolute top-2 right-2">
          <QuestionCircleOutlined
            style={{ fontSize: 16 }}
            className="text-secondary-black "
            onClick={showDetailsModal}
          />
        </Tooltip>

        <div className="flex justify-between">
          <div>
            <p className="text-secondary-black card-label">
              {title || "Invoice"} ID:
            </p>
            <Title level={4}>{invoice.id}</Title>
          </div>

          <div className="text-right">
            <p className="text-secondary-black card-label">Date:</p>
            <p>
              {" "}
              {moment(
                (invoice.dateCreated || invoice.dateGenerated) * 1000
              ).format("Do MMMM, YYYY")}{" "}
            </p>
          </div>
        </div>

        <div className="flex justify-between">
          <div>
            <p className="text-secondary-black card-label">Client Name:</p>
            <p> {client?.clientName || "-"} </p>
          </div>
          <div className="text-right">
            <p className="text-secondary-black card-label">Task Name:</p>
            <p> {task?.jobName || "-"} </p>
          </div>
        </div>

        <div className="mt-2">
          {/* <p className="text-secondary-black">List of {title || "Invoice"}</p> */}
          <div className="card-table overflow-y-auto h-56">
            <Table
              size="small"
              columns={columns}
              dataSource={invoice?.lineItems || invoice?.breakdown}
            />
          </div>
        </div>

        <div className="text-right mt-3 flex justify-between items-start">
          <Space>
            {title === "Invoice" && (
              <Tooltip title="Edit Invoice">
                <EditOutlined
                  style={{ fontSize: 16 }}
                  className="text-secondary-black "
                  onClick={_handleUpdateInvoice}
                />
              </Tooltip>
            )}
            <p className="bg-yellow-100 text-yellow-700 status">
              {" "}
              {getInvoiceStatus(invoice.status)}{" "}
            </p>

            {title === "Invoice" && type !== "invoice-request" && (
              <Tooltip title="Forward to mail">
                <Popover
                  trigger="click"
                  onVisibleChange={showSendMailPopover}
                  title="Forward Invoice / Receipt"
                  content={sendToMailPopOver}
                  visible={isSendMailPopOverVisible}
                >
                  <SendOutlined
                    style={{ fontSize: 20, cursor: "pointer" }}
                    className="text-yellow-700"
                    // onClick={showForwardToMailModal}
                  />
                </Popover>
              </Tooltip>
            )}

            {/* TODO: use title to differentiate instead of a variable name from data response */}
            {/* if data is requisition, show breakdown else if data is invoice, dont show */}
            {!invoice?.breakdown ? null : invoice.status == 0 ? (
              <Tooltip title="Reject Requisition">
                <Popconfirm
                  title="Are you sure you want to reject this Requisition?"
                  onConfirm={handleRejectRequisition}
                  onCancel={handleCancel}
                  okText="Reject"
                  cancelText="Cancel"
                >
                  <StopOutlined
                    style={{ fontSize: 20 }}
                    className="text-yellow-700"
                  />
                </Popconfirm>
              </Tooltip>
            ) : (
              <>
                <Tooltip title="Approve Requisition">
                  <Popconfirm
                    title="Are you sure you want to approve this Requisition?"
                    onConfirm={handleApproveRequisition}
                    onCancel={handleCancel}
                    okText="Approve"
                    cancelText="Cancel"
                  >
                    <CheckCircleOutlined
                      style={{ fontSize: 20 }}
                      className="text-yellow-700"
                    />
                  </Popconfirm>
                </Tooltip>
                {isLoading && <Spin size="small" />}
              </>
            )}
          </Space>

          <Title level={4} style={{ marginBottom: 0 }}>
            {" "}
            {/* If requisition, show amount requested. else if Invoice, show the total invoiceValue */}
            {formatMoney(invoice?.amount || invoice.invoiceValue)}{" "}
          </Title>
        </div>

        {/* <Button type="link">Payment History </Button> */}
      </Card>
    </>
  );
};

export default MoneyCardItem;
