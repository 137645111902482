import Modal from "antd/lib/modal/Modal";
import React from "react";
import Title from "antd/lib/typography/Title";
import { Form, Input, InputNumber, Button, Spin, Space } from "antd";
import { useMutation } from "react-query";
import { NotificationHandler } from "../../util";
import { updateClientRequest } from "../../api/ClientRequest";

const validateMessages = {
  required: "${label} is required!",
  types: {
    email: "${label} is not a valid email!",
    number: "${label} is not a valid number!",
  },
};

const EditClientModal = ({ visible, cancel, title, clientRecord, refetch }) => {
  const [form] = Form.useForm();
  const initialValues = [
    {
      name: ["data", "clientID"],
      value: clientRecord?.id,
    },
    {
      name: ["data", "clientName"],
      value: clientRecord?.clientName,
    },
    {
      name: ["data", "contactEmail"],
      value: clientRecord?.contactEmail,
    },
    {
      name: ["data", "contactPhone"],
      value: clientRecord?.contactPhone,
    },
    {
      name: ["data", "address"],
      value: clientRecord?.address,
    },
    {
      name: ["data", "allowableMinimumBalance"],
      value: clientRecord?.allowableMinimumBalance,
    },
  ];

  const mutation = useMutation(updateClientRequest, {
    onSuccess: (data, variables, context) => {
      const { data: response } = data;
      if (response?.error?.status === 1) {
        NotificationHandler("error", response.error.message);
      } else {
        NotificationHandler("success", response?.success?.message);
        refetch();
        cancel();
        form.resetFields();
        //   navigate(`/${title.toLowerCase()}`);
      }
    },
    onError: (error, variables, context) => {
      NotificationHandler("error", error.message);
    },
  });

  const onFinish = (values) => {
    mutation.mutate(values);
  };
  return (
    <Modal visible={visible} onCancel={cancel} footer={[]}>
      <Title className="mt-5 " level={4}>
        {title}
      </Title>
      <div>
        <Form
          layout="vertical"
          name="nest-messages"
          onFinish={onFinish}
          validateMessages={validateMessages}
          fields={initialValues}
          form={form}
        >
          <Form.Item name={["data", "clientID"]} label="id" hidden>
            <Input />
          </Form.Item>
          <Form.Item
            name={["data", "clientName"]}
            label="Client Name"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name={["data", "contactEmail"]}
            label="Email"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input.TextArea />
          </Form.Item>
          <Form.Item
            name={["data", "contactPhone"]}
            label="Client Phone"
            rules={[
              {
                // type: "number",
                required: true,
              },
            ]}
          >
            <Input type="number" style={{ width: "100%" }} />
          </Form.Item>

          <Form.Item
            name={["data", "address"]}
            label="Address"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input.TextArea />
          </Form.Item>
          <Form.Item
            name={["data", "allowableMinimumBalance"]}
            label="Allowable Minimum"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <InputNumber style={{ width: "100%" }} />
          </Form.Item>
          <Form.Item>
            <Space size="middle">
              <Button type="default" onClick={cancel}>
                Cancel
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                //   disabled={mutation.isLoading}
              >
                Update
              </Button>
              {mutation.isLoading && <Spin />}
            </Space>
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
};

export default EditClientModal;
