import React, { useEffect, useState } from "react";
import { useQueries } from "react-query";
import { useNavigate } from "react-router-dom";
import { Button, Space, message, Switch } from "antd";
import PageTitle from "../../../components/PageTitle";
import { NotificationHandler } from "../../../util";
import { getInvoiceRequest } from "../../../api/InvoiceRequest";
import { getClientRequest, getTaskRequest } from "../../../api/ClientRequest";
import MoneyCardList from "../../../components/MoneyCardList";
import FilterMoneyCardList from "../../../components/Invoice/FilterMoneyCardList";
import MoneyCardSummary from "../../../components/MoneyCardSummary";
import MoneyTableList from "../../../components/MoneyTableList";
import { CSVLink } from "react-csv";

function InvoiceManagement() {
  const navigate = useNavigate();
  const [filterParam, setFilterParam] = useState(null);
  const [tableFilterParam, setTableFilterParam] = useState(null);
  const [isMoneyCardView, setIsMoneyCardView] = useState(true);
  const [tableListResponse, setTableListResponse] = useState(null);

  const [
    { data: invoice, isLoading, error, refetch, isIdle },
    { data: clients },
    { data: tasks },
  ] = useQueries([
    {
      queryKey: ["invoice", filterParam],
      queryFn: () => getInvoiceRequest(filterParam),
      onError: (error) => {
        NotificationHandler("error", error.message);
      },
      retry: false,
      refetchOnWindowFocus: false,
      // enabled: !!filterParam,
    },
    {
      queryKey: "clients",
      queryFn: () => getClientRequest(),
      onError: (error) => {
        NotificationHandler("error", error.message);
        message.error(error.message);
      },
      retry: false,
      refetchOnWindowFocus: false,
    },
    {
      queryKey: "jobs",
      queryFn: () => getTaskRequest(),
      refetchOnWindowFocus: false,
      retry: false,
    },
  ]);

  const tasksData = tasks?.data?.content?.data;
  const clientsData = clients?.data?.content?.data;
  const invoiceData = invoice?.data?.content;

  // TODO: Complete this code block to sort the invoice data to show the most recent invoice at the top of list.
  // useEffect(() => {
  //   console.log("original array is ", invoiceData.data);
  //   // Object.entries(invoiceData.data).map(([key, value], index) => {
  //   //   console.log(`key is ${key}  and value is ${value}`);
  //   // });
  //   Object.entries(invoiceData.data).sort(
  //     ([key, value], [key2, value2], index) => {
  //       console.log(`key is ${key}  and value is ${value}`);
  //       return key - key2;
  //     }
  //   );
  // }, [invoiceData]);

  const filterInvoice = (filterParams) => {
    setFilterParam(filterParams);
  };

  const filterTableListInvoice = (filterParams) => {
    setTableFilterParam(filterParams);
  };

  const handleSwitchDataView = () => {
    setIsMoneyCardView(!isMoneyCardView);
  };

  const handleTableSummary = (response) => {
    const content = response?.data?.content;
    setTableListResponse(content);
  };

  return (
    <div>
      <div className="flex flex-wrap justify-between">
        <div>
          <PageTitle title="Manage Invoice" />
          <Space size="middle">
            {/* /invoice/:add-invoice points to <NewInvoiceForm /> */}
            <Button
              type="default"
              onClick={() => navigate("/invoice/add-invoice")}
            >
              New Invoice
            </Button>

            <Button
              type="default"
              onClick={() => navigate("/invoice/invoice-requests")}
            >
              Vendor Requests
            </Button>
            {isMoneyCardView ? (
              <FilterMoneyCardList
                title="Invoice"
                filterInvoice={filterInvoice}
                isLoading={isLoading}
                tasks={tasksData}
                clients={clientsData}
              />
            ) : (
              <FilterMoneyCardList
                title="Invoice List"
                filterTableListInvoice={filterTableListInvoice}
                tasks={tasksData}
                clients={clientsData}
              />
            )}
          </Space>
          <div className="mt-4">
            <Switch
              checkedChildren="Card View"
              unCheckedChildren="List View"
              onChange={handleSwitchDataView}
              defaultChecked
            />
          </div>
        </div>

        <MoneyCardSummary
          title="invoice"
          summary={isMoneyCardView ? invoiceData : tableListResponse}
        />
      </div>

      {isMoneyCardView ? (
        <MoneyCardList
          invoiceData={invoice?.data}
          loading={isLoading}
          title="Invoice"
          clients={clientsData}
          tasks={tasksData}
          refetch={refetch}
        />
      ) : (
        <MoneyTableList
          title="Invoice List"
          tableFilterParam={tableFilterParam}
          handleTableSummary={handleTableSummary}
        />
      )}
    </div>
  );
}

export default InvoiceManagement;
