import React, { useState } from "react";
import {
  Button,
  Popover,
  InputNumber,
  Form,
  Space,
  Spin,
  DatePicker,
  Select,
  message,
} from "antd";
import { FilterFilled } from "@ant-design/icons";
import { STATUS } from "../../util";
import { defaultPrefixCls } from "antd/lib/config-provider";
// import { useQuery } from "react-query";
// import { getInvoiceRequest } from "../../api/InvoiceRequest";
// import { getRequisitionRequest } from "../../api/RequisitionRequest";

const { Option } = Select;

const validateMessages = {
  required: "${label} is required!",
  types: {
    email: "${label} is not a valid email!",
    number: "${label} is not a valid number!",
  },
};

const FilterMoneyCardList = ({
  title,
  filterInvoice,
  isLoading,
  filterRequisition,
  tasks,
  clients,
  filterTableListInvoice,
  filter,
}) => {
  const [form] = Form.useForm();
  const [popOverVisible, setPopOverVisible] = useState(false);

  const handleFilterInvoice = (values) => {
    // if (title === "Invoice") {
    //   filterInvoice(values);
    // } else if (title === "Requisition") {
    //   filterRequisition(values);
    // } else {
    // }

    switch (title) {
      case "Invoice":
        filterInvoice(values);
        break;
      case "Requisition":
        filterRequisition(values);
        break;
      case "Invoice List":
        filterTableListInvoice(values);
        break;
      default:
        return;
    }
  };

  const handleVisibleChange = (visible) => {
    setPopOverVisible(visible);
  };

  const content = (
    <div className="h-32 overflow-y-auto">
      <Form
        layout="vertical"
        name="nest-messages"
        onFinish={handleFilterInvoice}
        validateMessages={validateMessages}
        form={form}
        size="small"
      >
        <Form.Item
          name={"dateFrom"}
          label="From Date"
          style={{ marginBottom: "5px" }}
        >
          <DatePicker />
        </Form.Item>
        <Form.Item
          name={"toDate"}
          label="To Date"
          style={{ marginBottom: "5px" }}
        >
          <DatePicker />
        </Form.Item>
        <Form.Item
          name={"id"}
          label={`${title} ID`}
          style={{ marginBottom: "5px" }}
          //   rules={[{ type: "number", required: true }]}
        >
          <InputNumber style={{ width: "100%" }} />
        </Form.Item>
        <Form.Item
          name={"clientID"}
          label="Client"
          style={{ marginBottom: "5px" }}
          //   rules={[{ type: "number", required: true }]}
        >
          {/* <InputNumber style={{ width: "100%" }} /> */}
          <Select placeholder="Select Client" allowClear>
            <Option value="-" key="all">
              All
            </Option>
            {clients &&
              clients.map((item, index) => (
                <Option value={item.id} key={index}>
                  {item.clientName}
                </Option>
              ))}
          </Select>
        </Form.Item>
        <Form.Item
          name={"jobID"}
          label="Task ID"
          style={{ marginBottom: "5px" }}
          //   rules={[{ type: "number", required: true }]}
        >
          {/* <InputNumber style={{ width: "100%" }} /> */}
          <Select placeholder="Select Task" allowClear>
            <Option value="-" key="all">
              All
            </Option>
            {tasks &&
              tasks.map((item, index) => (
                <Option value={item.id} key={index}>
                  {item.jobName}
                </Option>
              ))}
          </Select>
        </Form.Item>

        <Form.Item
          name={"status"}
          label="Status"
          style={{ marginBottom: "15px" }}
        >
          <Select placeholder="Select Status" allowClear>
            {STATUS.map((item, index) => (
              <Option value={item.key} key={index}>
                {item.message}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item>
          <Space size="middle">
            <Button type="default">Cancel</Button>
            <Button type="primary" htmlType="submit" disabled={isLoading}>
              Filter
            </Button>
            {isLoading && <Spin />}
          </Space>
        </Form.Item>
      </Form>
    </div>
  );

  return (
    <div className="">
      <Popover
        placement="right"
        content={content}
        trigger="click"
        visible={popOverVisible}
        onVisibleChange={handleVisibleChange}
      >
        <Button
          icon={<FilterFilled />}
          type="default"
          style={{ textAlign: "center", border: "none" }}
        >
          Filter {title}
        </Button>
      </Popover>
    </div>
  );
};

export default FilterMoneyCardList;
