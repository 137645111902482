import { Card, Avatar, Button, Space } from "antd";
import {
  ChromeFilled,
  PhoneFilled,
  TagFilled,
  UserOutlined,
} from "@ant-design/icons";
import React, { useState } from "react";
import { Typography } from "antd";
import UpdateProfileModal from "./UpdateProfileModal";
import UpdatePasswordModal from "./UpdatePasswordModal";
import { getAccessLevel } from "../../util";

const { Title } = Typography;

function UserCard({ userProfile, refetch }) {
  const [isUpdateModalVisible, setIsUpdateModalVisible] = useState(false);
  const [isPasswordModalVisible, setIsPasswordModalVisible] = useState(false);

  const showUpdateModal = () => {
    setIsUpdateModalVisible(true);
  };

  const closeUpdateModal = () => {
    setIsUpdateModalVisible(false);
  };

  const showPasswordModal = () => {
    setIsPasswordModalVisible(true);
  };

  const closePasswordModal = () => {
    setIsPasswordModalVisible(false);
  };

  return (
    <>
      <UpdateProfileModal
        visible={isUpdateModalVisible}
        cancel={closeUpdateModal}
        userProfile={userProfile}
        refetch={refetch}
      />

      <UpdatePasswordModal
        visible={isPasswordModalVisible}
        cancel={closePasswordModal}
        refetch={refetch}
      />
      <Card
        style={{
          // width: 350,
          // maxWidth: 500,
          textAlign: "center",
          marginBottom: "20px",
        }}
      >
        <Avatar
          size={{
            //   xs: 24,
            //   sm: 32,
            //   md: 40,
            lg: 64,
            xl: 80,
            xxl: 100,
          }}
          icon={<UserOutlined />}
        />
        <Title
          level={4}
          style={{ color: "#ec7c30", marginBottom: 10, marginTop: 20 }}
        >
          {userProfile.fullname}
        </Title>
        <div className="flex items-center justify-center my-1">
          <ChromeFilled className="text-secondary-black" />{" "}
          <p className="ml-2 text-lg text-secondary-black">
            {userProfile.email}
          </p>
        </div>
        <div className="flex items-center justify-center my-1">
          <PhoneFilled className="text-secondary-black" />{" "}
          <p className="ml-2 text-lg text-secondary-black">
            {userProfile.phone}
          </p>
        </div>
        <div className="flex items-center justify-center my-1">
          <UserOutlined className="text-secondary-black" />{" "}
          <p className="ml-2 text-lg text-secondary-black">
            {userProfile.username}
          </p>
        </div>
        <div className="flex items-center justify-center my-1">
          <TagFilled className="text-secondary-black" />{" "}
          <p className="ml-2 text-lg text-primary-black">
            {getAccessLevel(userProfile.accessLevel)}
          </p>
        </div>
        <Space className="mt-4">
          <Button onClick={showUpdateModal}>Update Profile</Button>
          <Button onClick={showPasswordModal}>Update Password</Button>
        </Space>
      </Card>
    </>
  );
}

export default UserCard;
