import {
  Col,
  Select,
  Button,
  Row,
  Form,
  Space,
  Spin,
  message,
  Input,
} from "antd";
import Modal from "antd/lib/modal/Modal";
import Title from "antd/lib/typography/Title";
import React from "react";
import { useMutation, useQueries } from "react-query";
import { useNavigate } from "react-router-dom";
import { updateInvoiceRequest } from "../../api/InvoiceRequest";
import { NotificationHandler } from "../../util";
import FormLineItemDynamic from "../FormLineItemDynamic";

const validateMessages = {
  required: "${label} is required!",
  types: {
    email: "${label} is not a valid email!",
    number: "${label} is not a valid number!",
  },
  number: {
    range: "${label} must be between ${min} and ${max}",
  },
};

const { Option } = Select;

const UpdateInvoiceModal = ({
  visible,
  cancel,
  info,
  refetch,
  tasksList,
  clientsList,
}) => {
  const [form] = Form.useForm();
  const mutation = useMutation(updateInvoiceRequest, {
    onSuccess: (data, variables, context) => {
      const { data: response } = data;
      if (response?.error?.status === 1) {
        NotificationHandler("error", response.error.message);
      } else {
        NotificationHandler("success", response?.success?.message);
        refetch();
        cancel();
      }
    },
    onError: (error, variables, context) => {
      NotificationHandler("error", error.message);
    },
  });

  const initialValues = [
    {
      name: "id",
      value: info?.id,
    },
    {
      name: "client",
      value: info?.client,
    },
    {
      name: "job",
      value: info?.task,
    },
  ];

  const onFinish = (values) => {
    // console.log("finish update", values);
    mutation.mutate(values);
  };

  return (
    <Modal visible={visible} onCancel={cancel} footer={[]} width="80%">
      <Title level={4}>Update Invoice</Title>

      <Form
        layout="vertical"
        name="nest-messages"
        form={form}
        onFinish={onFinish}
        validateMessages={validateMessages}
        className="mt-6"
        fields={initialValues}
      >
        <Row gutter={24} className="my-4">
          <Form.Item name="id" label="id" hidden>
            <Input />
          </Form.Item>

          <Col xs={24} md={12}>
            <Form.Item
              name="client"
              label="Client Name"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input disabled />
            </Form.Item>
          </Col>

          <Col xs={24} md={12}>
            <Form.Item
              name="job"
              label="Task Name"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              {/* <Input disabled /> */}
              {form.getFieldValue("job") !== undefined ? (
                <Input disabled />
              ) : (
                <Select placeholder="Select Task" allowClear>
                  {tasksList !== undefined &&
                    tasksList.length > 0 &&
                    tasksList.map((item, index) => {
                      let clientName;
                      if (clientsList !== undefined) {
                        clientName = clientsList.map((clientItem) => {
                          if (clientItem.id == item.clientID) {
                            return clientItem.clientName;
                          }
                        });
                      }

                      return (
                        <Option value={item.id}>
                          {item.jobName} <strong>for</strong> {clientName}
                        </Option>
                      );
                    })}
                </Select>
              )}
            </Form.Item>
          </Col>
        </Row>

        <FormLineItemDynamic
          form={Form}
          title="lineItems"
          _form={form}
          action="update"
          invoiceData={info.invoice}
        />

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            disabled={mutation.isLoading}
          >
            Submit
          </Button>
          {mutation.isLoading && <Spin />}
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default UpdateInvoiceModal;
