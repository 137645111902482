import {
  Form,
  Row,
  Col,
  Button,
  Spin,
  Select,
  message,
  Input,
  InputNumber,
} from "antd";
import { useMutation, useQueries, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { createInvoiceRequest } from "../../api/InvoiceRequest";
import { getClientRequest, getTaskRequest } from "../../api/ClientRequest";
import { NotificationHandler } from "../../util";
import FormLineItemDynamic from "../FormLineItemDynamic";
import PageTitle from "../PageTitle";
import { createRequisitionRequest } from "../../api/RequisitionRequest";

const validateMessages = {
  required: "${label} is required!",
  types: {
    email: "${label} is not a valid email!",
    number: "${label} is not a valid number!",
  },
};

const { Option } = Select;

const CreateRequisitionForm = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [{ data: clients }, { data: tasks }] = useQueries([
    {
      queryKey: "clients",
      queryFn: () => getClientRequest(),
      onError: (error) => {
        NotificationHandler("error", error.message);
        message.error(error.message);
      },
      retry: false,
      refetchOnWindowFocus: false,
    },
    {
      queryKey: "jobs",
      queryFn: () => getTaskRequest(),
    },
  ]);

  const mutation = useMutation(createRequisitionRequest, {
    onSuccess: (data, variables, context) => {
      const { data: response } = data;
      if (response?.error?.status === 1) {
        NotificationHandler("error", response.error.message);
      } else {
        NotificationHandler("success", response?.success?.message);
        navigate("/requisition");
      }
    },
    onError: (error, variables, context) => {
      NotificationHandler("error", error.message);
    },
  });

  const clientsList = clients?.data?.content?.data;
  const tasksList = tasks?.data?.content?.data;

  const onFinish = (values) => {
    mutation.mutate(values);
  };

  return (
    <div>
      <PageTitle title="New Requisition" />

      <Form
        layout="vertical"
        name="nest-messages"
        form={form}
        onFinish={onFinish}
        validateMessages={validateMessages}
        className="mt-6"
      >
        <Row gutter={24} className="my-4">
          <Col xs={24} md={12}>
            <Form.Item
              name="clientID"
              label="Client Name"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select placeholder="Select Client" allowClear>
                {clientsList !== undefined &&
                  clientsList.length > 0 &&
                  clientsList.map((item, index) => (
                    <Option value={item.id}> {item.clientName} </Option>
                  ))}
                {/* <Option value={0}>Client Name</Option>
                <Option value={1}>Client Name two</Option> */}
              </Select>
            </Form.Item>
          </Col>

          <Col xs={24} md={12}>
            <Form.Item
              name="jobID"
              label="Task Name"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select placeholder="Select Task" allowClear>
                {tasksList !== undefined &&
                  tasksList.length > 0 &&
                  tasksList.map((item, index) => {
                    let clientName;
                    if (clientsList !== undefined) {
                      clientName = clientsList.map((clientItem) => {
                        if (clientItem.id == item.clientID) {
                          return clientItem.clientName;
                        }
                      });
                    }

                    return (
                      <Option value={item.id}>
                        {item.jobName} <strong>for</strong> {clientName}
                      </Option>
                    );
                  })}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={24} className="my-4">
          <Col xs={24} md={8}>
            <Form.Item
              name="name"
              label="Name"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col xs={24} md={8}>
            <Form.Item
              name="amount"
              label="Amount"
              rules={[{ type: "number", required: true }]}
            >
              <InputNumber style={{ width: "100%" }} />
            </Form.Item>
          </Col>

          <Col xs={24} md={8}>
            <Form.Item name="details" label="Details">
              <Input.TextArea placeholder="Details... (Optional)" />
            </Form.Item>
          </Col>
        </Row>

        {/* {inputList.map((item, index) => (
        <FormLineItem
          item={item}
          idx={index}
          form={Form}
          thisForm={form}
          handleAddLineItem={handleAddLineItem}
          handleRemoveLineItem={handleRemoveLineItem}
          values={inputList}
        />
      ))} */}
        <FormLineItemDynamic form={Form} title="breakdown" />

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            disabled={mutation.isLoading}
          >
            Submit
          </Button>
          {mutation.isLoading && <Spin />}
        </Form.Item>
      </Form>
    </div>
  );
};

export default CreateRequisitionForm;
