import { Form, Input, Button, Spin } from "antd";
import { useMutation } from "react-query";
import { useLocation } from "react-router-dom";
import { createTaskRequest } from "../api/ClientRequest";
import { NotificationHandler } from "../util";

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 12,
  },
};

const validateMessages = {
  required: "${label} is required!",
  types: {
    email: "${label} is not a valid email!",
    number: "${label} is not a valid number!",
  },
  number: {
    range: "${label} must be between ${min} and ${max}",
  },
};

const NewTaskForm = ({ toggleAssignTaskFormVisible, refetch }) => {
  const { state } = useLocation();
  const mutation = useMutation(createTaskRequest, {
    onSuccess: (data, variables, context) => {
      const { data: response } = data;
      if (response?.error?.status === 1) {
        NotificationHandler("error", response.error.message);
      } else {
        NotificationHandler("success", response?.success?.message);
        refetch();
        toggleAssignTaskFormVisible();
      }
    },
    onError: (error, variables, context) => {
      NotificationHandler("error", error.message);
    },
  });

  const onFinish = (values) => {
    mutation.mutate(values);
  };

  return (
    <Form
      {...layout}
      name="nest-messages"
      onFinish={onFinish}
      validateMessages={validateMessages}
    >
      <Form.Item
        name={["data", "clientID"]}
        label="Client Name"
        hidden
        initialValue={state.id}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name={["data", "jobName"]}
        label="Task Name"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name={["data", "details"]}
        label="Task Details"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input.TextArea />
      </Form.Item>
      <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 8 }}>
        <Button type="primary" htmlType="submit" disabled={mutation.isLoading}>
          Submit
        </Button>
        {mutation.isLoading && <Spin />}
      </Form.Item>
    </Form>
  );
};

export default NewTaskForm;
